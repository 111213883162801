import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import ShadowBox from "../ShadowBox";
import CustomBreadcrumbs from "../CustomBreadcrumbs";
import { listarPromocionestxsRequest } from "../../connection/mantenedores/promocionesMantenedor";
import { getToken } from "../../connection/tokenController";
import { PROMO_MANTENEDOR_INPAGE } from "../../pages/promociones/PromocionesMantenedor";
import { useLoader } from "../../context/LoaderContext";
import RoundButton from "../RoundButton";

const breadcrumbItems = [
  { href: "/promociones", label: "Promociones" },
  { label: "Reportes" },
];

const NombreMeses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const PromocionesReportes = () => {
  const location = useLocation();

  const { showLoader, hideLoader, showNotification } = useLoader();
  const { idPromo, idPromocion, promocion = {} } = location.state || {};
  const { cantidad = 0 } = promocion;
  const [meses, setMeses] = useState([]);
  const [ventasPorMes, setVentasPorMes] = useState([]);
  const [transaccionesRealizadas, setTransaccionesRealizadas] = useState(0);
  let navigate = useNavigate();

  const ObtenerTxsPromocion = async () => {
    showLoader();
    try {
      const token = getToken();
      const respuesta = await listarPromocionestxsRequest(idPromocion, token);

      if (respuesta && Array.isArray(respuesta)) {
        const ventasMap = new Map();
        let totalTransacciones = 0;

        respuesta.forEach(({ fechaDescuento, monto }) => {
          const mesIndex = new Date(fechaDescuento).getMonth();
          ventasMap.set(mesIndex, (ventasMap.get(mesIndex) || 0) + monto);
          totalTransacciones += 1;
        });

        const mesesOrdenados = Array.from(ventasMap.keys()).sort(
          (a, b) => a - b
        );
        const ventasOrdenadas = mesesOrdenados.map((mes) => ventasMap.get(mes));

        setMeses(mesesOrdenados.map((mes) => NombreMeses[mes]));
        setVentasPorMes(ventasOrdenadas);
        setTransaccionesRealizadas(totalTransacciones);
      }
    } catch (error) {
      console.error(error);
      showNotification("error", "ha ocurrido un error");
    } finally {
      hideLoader();
    }
  };

  const handleVolver = () => {
    navigate("/promociones");
  };

  const cantidadLibre = Math.max(0, cantidad - transaccionesRealizadas);
  const totalVentas = ventasPorMes.reduce((acc, monto) => acc + monto, 0);

  const totalVentasFormatted = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(totalVentas);

  useEffect(() => {
    localStorage.setItem(PROMO_MANTENEDOR_INPAGE, "true");
    ObtenerTxsPromocion();
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        REPORTES
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <CustomBreadcrumbs items={breadcrumbItems} />
        <Container maxWidth="xl" sx={{ mt: 3 }}>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h2>PROMOCIÓN:&nbsp;{idPromo || "Promoción no disponible"}</h2>
            </Grid>
          </Grid>
          {transaccionesRealizadas === 0 ? (
            <Grid item xs={12} md={6}>
              <Typography sx={{ textAlign: "center" }}>
                Esta promoción no ha sido utilizada.
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <RoundButton type="button" onClick={handleVolver}>
                  Volver Atrás
                </RoundButton>
              </Box>
            </Grid>
          ) : (
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                {/* Gráfico de barras */}
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Total de Ventas por Mes
                    </Typography>

                    <Box display="flex" justifyContent="left" sx={{ p: 2 }}>
                      <BarChart
                        xAxis={[{ scaleType: "band", data: meses }]}
                        series={[{ data: ventasPorMes }]}
                        width={400}
                        height={400}
                      />
                    </Box>
                  </CardContent>
                  <Typography sx={{ textAlign: "center", marginBottom: "7px" }}>
                    Monto General: {totalVentasFormatted}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* Gráfico de torta */}
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "center", mb: 2 }}
                    >
                      Cantidad de Promociones Disponibles
                    </Typography>
                    <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
                      <PieChart
                        series={[
                          {
                            data: [
                              {
                                id: 0,
                                value: transaccionesRealizadas,
                                label: "Ventas",
                              },
                              {
                                id: 1,
                                value: cantidadLibre,
                                label: "Disponibles",
                              },
                            ],
                          },
                        ]}
                        colors={["#02B2AF", "#3cc967"]}
                        width={600}
                        height={383}
                        sx={{ mx: "auto" }}
                      />
                    </Box>

                    <Typography sx={{ textAlign: "center" }}>
                      Total: {cantidad} - Ventas: {transaccionesRealizadas} -
                      Disponibles: {cantidadLibre}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </ShadowBox>
    </>
  );
};

export default PromocionesReportes;
