export const mcPadresHeaderConfig = [
  { key: "", label: "", style: { width: "1px", whiteSpace: "nowrap" } },
  {
    key: "numeroComercioGetnet",
    label: "N° COMERCIO",
    sortable: true,
    filter: true,
    sx: { whiteSpace: "nowrap", width: '1px' },
  },
  {
    key: "nombreComercio",
    label: "COMERCIO",
    sortable: true,
    filter: true
  },
  { key: "rut", label: "RUT COMERCIO", sortable: true, filter: true },
  { key: "", label: "ACCION", sortable: false, filter: false },
];

export const mcHijosHeaderConfig = [
  {
    key: "numeroComercioGetnet",
    label: "N° COMERCIO",
    sortable: true,
    sx: { whiteSpace: "nowrap" },
  },
  {
    key: "nombreComercio",
    label: "COMERCIO",
    sortable: true,
  },
  { key: "rut", label: "RUT COMERCIO", sortable: true },
  { key: "", label: "ACCION", sortable: false, filter: false },
];

