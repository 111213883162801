import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";

export const opcionesTypes = {
  ENTERO: 1,
  TEXTO: 2,
};

export const opcionesParams = {
  indicadorBiomoneda: [
    {
      label: "0: Peso chileno",
      value: 0,
    },
    {
      label: "1: Biomoneda",
      value: 1,
    },
  ],
  indicadorBoleta: [
    {
      label: "0: No permite Digitar boleta",
      value: 0,
    },
    {
      label: "1: Permite Digitar boleta",
      value: 1,
    },
  ],
  indicadorNoVendedor: [
    {
      label: "0: Deshabilita Empleado",
      value: 0,
    },
    {
      label: "1: Habilita Empleado",
      value: 1,
    },
  ],
  indicadorComprobanteComoBoleta: [
    {
      label: "0: Deshabilita voucher boleta",
      value: 0,
    },
    {
      label: "1: Habilita voucher como Boleta",
      value: 1,
    },
    {
      label: "2: Habilita boleta electrónica",
      value: 2,
    },
  ],
  indicadorPropina: [
    {
      label: "0:  Deshabilita Propina",
      value: 0,
    },
    {
      label: "1: Habilita Propina",
      value: 1,
    },
  ],
  indicadorVuelto: [
    {
      label: "0:  Deshabilita Vuelto",
      value: 0,
    },
    {
      label: "1: Habilita Vuelto (Cahsback)",
      value: 1,
    },
  ],
  indicadorCuotasEmisor: [
    {
      label: "0:  Deshabilita Cuotas Emisor",
      value: 0,
    },
    {
      label: "1: Activo Cuotas Emisor",
      value: 1,
    },
  ],
  minimoCuotasEmisor: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  maximoCuotasEmisor: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  indicadorCuotasComercio: [
    {
      label: "0:  Deshabilita Cuotas comercio",
      value: 0,
    },
    {
      label: "1: Activo Cuotas comercio",
      value: 1,
    },
  ],
  minimoCuotasComercio: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  maximoCuotasComercio: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  indicadorCuotasTasaCero: [
    {
      label: "0:  Deshabilita Cuotas Tasa Cero.",
      value: 0,
    },
    {
      label: "1:  Activo Cuotas Tasa Cero.",
      value: 1,
    },
  ],
  minimoCuotasTasaCero: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  maximoCuotasTasaCero: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  indicadorCuotasTasaInteresConocida: [
    {
      label: "0:  Deshabilita Tasa de Interés Conocida.",
      value: 0,
    },
    {
      label: "1: Activo Tasa de Interés Conocida.",
      value: 1,
    },
  ],
  minimoCuotasTasaInteresConocida: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  maximoCuotasTasaInteresConocida: {
    type: opcionesTypes.ENTERO,
    min: 0,
    max: 99,
  },
  tipoProductoCreditoVisa: [
    {
      label: "0:  Deshabilita Producto Crédito Visa",
      value: 0,
    },
    {
      label: "1: Habilita Producto Crédito Visa",
      value: 1,
    },
  ],
  tipoProductoDebitoVisa: [
    {
      label: "0:  Deshabilita Producto Debito Visa",
      value: 0,
    },
    {
      label: "1: Habilita Producto Debito Visa",
      value: 1,
    },
  ],
  tipoProductoDebitoVisaElectron: [
    {
      label: "0: Deshabilita producto Débito Visa Electrón",
      value: 0,
    },
    {
      label: "1: Habilita producto Débito Visa Electrón",
      value: 1,
    },
  ],
  tipoProductoPrepagoVisa: [
    {
      label: "0: Deshabilita producto Prepago Visa",
      value: 0,
    },
    {
      label: "1: Habilita producto Prepago Visa",
      value: 1,
    },
  ],
  tipoProductoCreditoMastercard: [
    {
      label: "0:   Deshabilita producto Crédito MasterCard",
      value: 0,
    },
    {
      label: "1: Habilita producto Crédito MasterCard",
      value: 1,
    },
  ],
  tipoProductoDebitoMastercard: [
    {
      label: "0:  Deshabilita producto Débito MasterCard",
      value: 0,
    },
    {
      label: "1: Habilita producto Débito MasterCard",
      value: 1,
    },
  ],
  tipoProductoDebitoMaestro: [
    {
      label: "0:  Deshabilita producto Débito Maestro",
      value: 0,
    },
    {
      label: "1: Habilita producto Débito Maestro",
      value: 1,
    },
  ],
  tipoProductoPrepagoMastercard: [
    {
      label: "0:  Deshabilita producto Prepago MasterCard",
      value: 0,
    },
    {
      label: "1:  Habilita producto Prepago MasterCard",
      value: 1,
    },
  ],
  tipoProductoCreditoAmex: [
    {
      label: "0:  Deshabilita producto Crédito Amex",
      value: 0,
    },
    {
      label: "1: Habilita producto Crédito Amex",
      value: 1,
    },
  ],
  tipoProductoDebitoAmex: [
    {
      label: "0:  Deshabilita producto Débito Amex",
      value: 0,
    },
    {
      label: "1: Habilita producto Débito Amex",
      value: 1,
    },
  ],
  tipoProductoPrepagoAmex: [
    {
      label: "0:  Deshabilita producto Prepago Amex",
      value: 0,
    },
    {
      label: "1: Habilita producto Prepago Amex",
      value: 1,
    },
  ],
  tipoProductoMagna: [
    {
      label: "0:  Deshabilita producto Magna",
      value: 0,
    },
    {
      label: "1: Habilita producto Magna",
      value: 1,
    },
  ],
  numeroDeFolio: [
    {
      label: "0:  Deshabilita Número de Folio",
      value: 0,
    },
    {
      label: "1: Habilita Número de Folio",
      value: 1,
    },
  ],
  posAvance: [
    {
      label: "0:  Deshabilita Avance en Efectivo",
      value: 0,
    },
    {
      label: "1: Habilita Avance en Efectivo",
      value: 1,
    },
  ],
};

export const paramsCommerce = [
  {
    label: "Indicador Biomoneda",
    atributeName: "indicadorBiomoneda",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorBiomoneda,
  },
  {
    label: "Indicador Boleta",
    atributeName: "indicadorBoleta",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorBiomoneda,
  },
  {
    label: "Indicador Comprobande como Boleta",
    atributeName: "indicadorComprobanteComoBoleta",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorComprobanteComoBoleta,
  },
  {
    label: "indicador No Vendedor",
    atributeName: "indicadorNoVendedor",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorNoVendedor,
  },
  {
    label: "Indicador Propina",
    atributeName: "indicadorPropina",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorPropina,
  },
  {
    label: "indicador Vuelto",
    atributeName: "indicadorVuelto",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorVuelto,
  },
  {
    label: "indicador Cuotas Emisor",
    atributeName: "indicadorCuotasEmisor",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorCuotasEmisor,
  },
  {
    label: "Minimo Cuotas Emisor",
    atributeName: "minimoCuotasEmisor",
    componente: CustomInput,
    opciones: opcionesParams.minimoCuotasEmisor,
  },
  {
    label: "Maximo Cuotas Emisor",
    atributeName: "maximoCuotasEmisor",
    componente: CustomInput,
    opciones: opcionesParams.maximoCuotasEmisor,
  },
  {
    label: "indicador Cuotas Comercio",
    atributeName: "indicadorCuotasComercio",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorCuotasComercio,
  },
  {
    label: "Minimo Cuotas Comercio",
    atributeName: "minimoCuotasComercio",
    componente: CustomInput,
    opciones: opcionesParams.minimoCuotasComercio,
  },
  {
    label: "Maximo Cuotas Comercio",
    atributeName: "maximoCuotasComercio",
    componente: CustomInput,
    opciones: opcionesParams.maximoCuotasComercio,
  },
  {
    label: "indicador Cuotas Tasa Cero",
    atributeName: "indicadorCuotasTasaCero",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorCuotasTasaCero,
  },
  {
    label: "Minimo Cuotas Tasa Cero",
    atributeName: "minimoCuotasTasaCero",
    componente: CustomInput,
    opciones: opcionesParams.minimoCuotasTasaCero,
  },
  {
    label: "Maximo Cuotas Tasa Cero",
    atributeName: "maximoCuotasTasaCero",
    componente: CustomInput,
    opciones: opcionesParams.maximoCuotasTasaCero,
  },
  {
    label: "Indicador Cuotas Tasa Interes Conocida",
    atributeName: "indicadorCuotasTasaInteresConocida",
    componente: CustomSelect,
    opciones: opcionesParams.indicadorCuotasTasaInteresConocida,
  },
  {
    label: "Minimo Cuotas Tasa Interes Conocida",
    atributeName: "minimoCuotasTasaInteresConocida",
    componente: CustomInput,
    opciones: opcionesParams.minimoCuotasTasaInteresConocida,
  },
  {
    label: "Maximo Cuotas Tasa Interes Conocida",
    atributeName: "maximoCuotasTasaInteresConocida",
    componente: CustomInput,
    opciones: opcionesParams.maximoCuotasTasaInteresConocida,
  },
  {
    label: "tipo Producto Credito Visa",
    atributeName: "tipoProductoCreditoVisa",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoCreditoVisa,
  },
  {
    label: "tipo Producto Debito Visa",
    atributeName: "tipoProductoDebitoVisa",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoDebitoVisa,
  },
  {
    label: "tipo Producto Debito Visa Electron",
    atributeName: "tipoProductoDebitoVisaElectron",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoDebitoVisaElectron,
  },
  {
    label: "tipo Producto Prepago Visa",
    atributeName: "tipoProductoPrepagoVisa",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoPrepagoVisa,
  },
  {
    label: "tipo Producto Credito Mastercard",
    atributeName: "tipoProductoCreditoMastercard",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoCreditoMastercard,
  },
  {
    label: "tipo Producto Debito Mastercard",
    atributeName: "tipoProductoDebitoMastercard",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoDebitoMastercard,
  },
  {
    label: "tipo Producto Debito Maestro",
    atributeName: "tipoProductoDebitoMaestro",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoDebitoMaestro,
  },
  {
    label: "tipo Producto Prepago Mastercard",
    atributeName: "tipoProductoPrepagoMastercard",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoPrepagoMastercard,
  },
  {
    label: "tipo Producto Credito Amex",
    atributeName: "tipoProductoCreditoAmex",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoCreditoAmex,
  },
  {
    label: "tipo Producto Debito Amex",
    atributeName: "tipoProductoDebitoAmex",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoDebitoAmex,
  },
  {
    label: "tipo Producto Prepago Amex",
    atributeName: "tipoProductoPrepagoAmex",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoPrepagoAmex,
  },
  {
    label: "tipo Producto Magna",
    atributeName: "tipoProductoMagna",
    componente: CustomSelect,
    opciones: opcionesParams.tipoProductoMagna,
  },
  {
    label: "numero De Folio",
    atributeName: "numeroDeFolio",
    componente: CustomSelect,
    opciones: opcionesParams.numeroDeFolio,
  },
  {
    label: "pos Avance",
    atributeName: "posAvance",
    componente: CustomSelect,
    opciones: opcionesParams.posAvance,
  },
];
