import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./tabs.css";

const NavigationTabs = ({tabs=[], onSelectTab}) => {
  const [tabSelected, setTabSelected] = useState(0);
  const handleChangeTab = (e, tabIndex, type) => {
    if (tabSelected !== tabIndex && type === "btn")
      return;
    setTabSelected(tabIndex);
    onSelectTab(tabIndex);
  }
  return (
      <Tabs value={tabSelected} onChange={handleChangeTab} aria-label="tabs navigation">
        {
          tabs.map((tab, idx) => <Tab key={idx} label={tab} onClick={(e) => handleChangeTab(e, idx, "btn")} />)
        }
      </Tabs>
  );
};

export default NavigationTabs;
