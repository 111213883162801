import React, { useEffect, useState } from "react";
import { editarSucursalMcRequest, listarSucursalesMcPorIdCommerceDataRequest } from "../../../connection/mantenedores/multicomercioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { useLoader } from "../../../context/LoaderContext";
import { compararASC } from "../../../utils/utilidades";

export default function useSucursalesMcMantenedor(idCommerceDataMc) {
  const { showLoader, hideLoader, showNotification } = useLoader();
    
  const [sucursales, setSucursales] = useState([]);
  const fetchSucursales = async () => {
    try {
      const token = getToken();
      const response = await listarSucursalesMcPorIdCommerceDataRequest(
        idCommerceDataMc,
        token
      );
      const ordenado = response.sort((a, b) => compararASC(a, b, "nombreSucursal"))
      setSucursales(ordenado);
    } catch {
      setSucursales([]);
    }
  };

  useEffect(() => {
    fetchSucursales();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditSucursal = async (sucursal) => {
    showLoader();
    try {
      const token = getToken();
      if (!token) {
        throw new Error("Token no encontrado");
      }
      sucursal.idCommerceDataMc = idCommerceDataMc;
      const res = await editarSucursalMcRequest(
        sucursal.idSucursalMc,
        sucursal,
        token
      );
      if (res) {
        showNotification("success", "Sucursal editada correctamente.");
        fetchSucursales();
      } else {
        showNotification(
          "error",
          "Hubo un problema al intentar guardar los cambios. Intenta nuevamente."
        );
      }
    } catch (error) {
      console.error("Error al editar sucursal:", error.message || error);
      showNotification(
        "error",
        "Hubo un problema al intentar guardar los cambios. Intenta nuevamente."
      );
    } finally {
      hideLoader();
    }
  };
  return {sucursales, handleEditSucursal}
}
