export const headerConfig = [
  {
    key: "fechaRequest", label: "FECHA", sortable: true
  },
  {
    key: "idMcTxs", label: "ID TXS", sortable: false
  },
  {
    key: "comando", label: "COMANDO", sortable: true
  },
  {
    key: "functionCode", label: "FUNCTION CODE", sortable: true
  },
  {
    key: "responseCode", label: "RESPONSE CODE", sortable: false
  },
  {
    key: "responseMessage", label: "RESPONSE MESSAGE", sortable: false
  },
  {
    key: "fechaResponse", label: "FECHA RESPONSE", sortable: false
  },
  { key: "", label: "ACCION", sortable: false, filter: false },
];

