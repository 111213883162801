import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  crearComercioRequest,
  editarComercioRequest,
} from "../../connection/mantenedores/comercioMantenedor";
import Input from "../../components/Input/Input";
import { useLoader } from "../../context/LoaderContext";
import { formatearRut, isProduction, validarRut } from "../../utils/utilidades";
import { getToken } from "../../connection/tokenController";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";


const breadcrumbItems = [
  { href: '/comercios', label: "Comercios" }
];


export default function EditarCrearUsuario(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [isEdit, setIsEdit] = useState(false);
  const [rutError, setRutError] = useState(false);

  const [inputsError, setInputsError] = useState("");
  const [comercio, setComercio] = useState({
    nombreComercio: "",
    rut: "",
    emailRepresentante: "",
    habilitado: true,
  });

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/comercios");
  };

  useEffect(() => {
    if (location.state?.comercio) {
      setComercio(location.state?.comercio);
      setIsEdit(true);
    }
    if (isProduction()) 
      navigate('/comercios');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeNombreComercio = (e) => {
    setComercio({ ...comercio, nombreComercio: e.target.value });
  };
  const handleChangeNComercioGetnet = (e) => {
    setComercio({ ...comercio, numeroComercioGetnet: e.target.value });
  }
  const handleChangeRut = (e) => {
    setRutError(false);
    setComercio({ ...comercio, rut: e.target.value });
  };
  const handleCrear = async (e) => {
    e.preventDefault();
    if (!validarDatos()) return;
    setInputsError("");
    const objeto = { ...comercio, rut: formatearRut(comercio.rut) };
    try {
      showLoader();
      const token = getToken();
      const respuesta = await sendRequestCrear(objeto, token);
      if (!respuesta.idComercio) {
        return;
      }
      showNotification("success", "Comercio creado con éxito");
    } catch (err) {
      console.error(err);
    } finally {
      hideLoader();
    }
    navigate("/comercios");
  };
  const sendRequestCrear = async (objeto, token) => {
    try {
      const respuesta = await crearComercioRequest(objeto, token);
      return respuesta;
    } catch (error) {
      if (error.status === 409) {
        showNotification("error", "Ya existe un comercio con este rut");
        return false;
      }
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return false;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      return false;
    }
  }
  const validarDatos = () => {
    if (comercio.numeroComercioGetnet === null) {
      setInputsError("Por favor, complete todos los campos.");
      return false;
    }
    if (!comercio.nombreComercio.trim() || !comercio.rut.trim()) {
      setInputsError("Por favor, complete todos los campos.");
      return false;
    }
    if (!validarRut(comercio.rut)) {
      setRutError(true);
      setInputsError("Ingrese un rut válido.");
      return false;
    }
    return true;
  };
  const handleEditar = async (e) => {
    e.preventDefault();
    if (!validarDatos()) return;
    setInputsError("");
    const token = getToken();
    const objeto = { ...comercio, rut: formatearRut(comercio.rut) };
    try {
      showLoader();
      const respuesta = sendRequestEdit(objeto, token);
      if (!respuesta) {
        return;
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      hideLoader();
    }
    showNotification("success", "Comercio editado con éxito");
      navigate("/comercios");
  };
  const sendRequestEdit = async (objeto, token) => {
    try {
      await editarComercio(objeto, token, comercio.idComercio);
      return true;
    } catch (error) {
      if (error.status === 409) {
        showNotification("error", "Ya existe un comercio con este rut");
        return false;
      }
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return false;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error)
      return false;
    }
  }

  const editarComercio = async (comercio, token, id) => {
    await editarComercioRequest(comercio, token, id);
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>
        {isEdit ? "EDITAR" : "NUEVO"} COMERCIO
      </Typography>
      <br />
      <ShadowBox style={{ padding: '2rem' }}>
        <CustomBreadcrumbs items={[...breadcrumbItems, isEdit ? { label: 'EDITAR COMERCIO' } : { label: 'NUEVO comercio' }]} />
        <form>
          <label style={{ color: "black" }}>N° COMERCIO GETNET</label>
          <Input
            type="text"
            placeholder="N° Comercio Getnet"
            value={comercio.numeroComercioGetnet === null ? "" : comercio.numeroComercioGetnet}
            onChange={handleChangeNComercioGetnet}
            maxLength={8}
          />
          <br />
          <label style={{ color: "black" }}>NOMBRE COMERCIO</label>
          <br />
          <Input
            type="text"
            placeholder="Nombre"
            value={comercio.nombreComercio}
            onChange={handleChangeNombreComercio}
          />
          <br />
          <label style={{ color: "black" }}>RUT COMERCIO</label>
          <Input
            type="text"
            placeholder="Rut"
            value={comercio.rut}
            onChange={handleChangeRut}
            maxLength={10}
            error={rutError}
          />
          {inputsError && (
            <div style={{ color: "red", marginTop: "10px" }}>{inputsError}</div>
          )}
          <br />
          <Button
            style={{ marginRight: "10px" }}
            variant="gray"
            onClick={handleVolver}
          >
            CANCELAR
          </Button>
          {isEdit ? (
            <Button onClick={handleEditar}>GUARDAR</Button>
          ) : (
            <Button onClick={handleCrear}>ACEPTAR</Button>
          )}
        </form>
      </ShadowBox>
    </>
  );
}
