import React, { useState } from 'react'
const initialState = {
    open: false,
    idCommerceDataMc: 0
  }
export default function useSucursalesMcDialog() {
  const [dialogData, setDialogData] = useState(initialState);

  const handleClose = () => {
    setDialogData(initialState)
  }
  const handleOpen = (idCommerceDataMc) => {
    setDialogData((prev) => ({open: true, idCommerceDataMc: idCommerceDataMc}));
  }

  return {
    dialogData,
    handleClose,
    handleOpen
  }
}
