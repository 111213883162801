import { useEffect, useState } from "react";
import "./styles.css";
import Eye from "./Eye";

function procesarValor(texto, min, max) {
  const valor = parseInt(texto);
  if (valor < min) return min;
  if (valor > max) return max;

  if (texto.length > 1 && texto[0] === "0")
    return texto.substring(1, texto.length);
  return texto;
}
export default function Input({
  type,
  className: propClassName,
  error,
  variant,
  disabled,
  validate,
  onChange,
  onFocus,
  onBlur,
  completeWidth,
  placeholder,
  required,
  value,
  style,
  min,
  max,
  maxLength,
  errorText,
}) {
  const [className, setClassName] = useState("");
  const [containerClassname, setContainerClassname] =
    useState("input_container");
  const [eyeOpen, setEyeOpen] = useState(false);
  const [showableType, setShowableType] = useState(type);

  const handleClassName = () => {
    let classes = propClassName || "";
    if (error) classes += " error";
    if (variant === "combobox") classes += " combobox";
    if (disabled === true) classes += " disabled";
    setClassName(classes);
  };
  useEffect(() => {
    handleClassName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propClassName, error, disabled]);

  const onChangeEvent = (e) => {
    e.preventDefault();
    if (validate) validate(e);
    if (type === "number") {
      e.target.value = procesarValor(e.target.value, min, max);
    }
    if (onChange) onChange(e);
  };
  const handleFocus = (e) => {
    if (!onFocus) return;
    onFocus(e);
  };
  const handleBlur = (e) => {
    if (!onBlur) return;
    setTimeout(() => {
      onBlur(e);
    }, 100);
  };
  useEffect(() => {
    if (completeWidth) {
      setContainerClassname(addClassname(containerClassname, "complete-width"));
    }
    if (variant === "combobox") {
      setContainerClassname(
        addClassname(containerClassname, "combobox_container")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleEye = () => {
    const nuevoValor = !eyeOpen;
    setEyeOpen(nuevoValor);
    setShowableType(nuevoValor ? "text" : "password");
  };

  return (
    <div className={containerClassname}>
      <input
        type={showableType}
        placeholder={placeholder}
        required={required}
        onChange={onChangeEvent}
        value={value || ""}
        className={className}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={style || null}
        min={min || null}
        max={max || null}
        maxLength={maxLength || null}
        disabled={disabled ? true : false}
      />
      {type === "password" ? (
        <Eye open={eyeOpen} onClick={handleToggleEye} />
      ) : (
        <></>
      )}
      {error && (
        <>
          <br />
          <label>{errorText}</label>
        </>
      )}
    </div>
  );
}

const addClassname = (clases, nuevaClase) => {
  if (clases.length === 0) {
    return nuevaClase;
  }
  const split = clases.split(" ");
  if (!containedClass(split, nuevaClase)) {
    return (clases += ` ${nuevaClase}`);
  }
  return clases;
};

const containedClass = (array, clase) => {
  return array.filter((x) => x.includes(clase)).length > 0;
};
