import { jwtDecode } from 'jwt-decode';

const USER_TOKEN_EXPIRES_DATE = "tkn_exp";
const USER_TOKEN = "userToken";


// Función para obtener el token del localStorage
export const getToken = () => {
  return localStorage.getItem(USER_TOKEN);
};
// Función para guardar el token en el localStorage
export const saveToken = (token, expiresIn) => {  
  localStorage.setItem(USER_TOKEN, token);
  saveExpirationDate(expiresIn);
};
export const removeToken = () => {
  localStorage.removeItem(USER_TOKEN_EXPIRES_DATE);
  localStorage.removeItem(USER_TOKEN);
};

export const retrieveToken = () => {
  try {
    const decodedToken = jwtDecode(getToken());
    return decodedToken;
  } catch (err) {
    console.error(err)
    return {};
  }
}

const saveExpirationDate = (expiresIn) => {
  const fecha = new Date();
  fecha.setSeconds(fecha.getSeconds() + expiresIn);
  localStorage.setItem(USER_TOKEN_EXPIRES_DATE, JSON.stringify(fecha));
}

export const getTokenExpiration = () => {
  try {
    const tokenDate = localStorage.getItem(USER_TOKEN_EXPIRES_DATE);
    if (!tokenDate)
      return null;
    const fechaString = JSON.parse(tokenDate);
    const fecha = new Date(fechaString);
    return fecha;
  }
  catch (error) {
    return null;
  }
}