import React, { useEffect, useState } from "react";
import { DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import Input from "../../../Input/Input";
import { useLoader } from "../../../../context/LoaderContext";
import { crearPosDataMcRequest } from "../../../../connection/mantenedores/multicomercioMantenedor";
import { getToken } from "../../../../connection/tokenController";
import Button from "../../../Button";
import Combobox from "../../../Combobox";
import { SELECTED_SUCURSAL_KEY } from "./PosDataMc";
import { tabs } from "../../dialogoSucursalesMc/SucursalesMcDialog";
import useSucursalesMc from "../../../../hooks/multicomercio/useSucursalesMc";

const CrearPosParamsDialogo = ({ idCommerceDataMc, onChangeTabInternal }) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [inputsError, setInputsError] = useState("");
  const {
    opcionSucursal,
    opcionesSucursales,
    onObtenerSucursales,
    onSelectOpcionSucursal,
    onUnSelectOpcionSucursal,
  } = useSucursalesMc(SELECTED_SUCURSAL_KEY);

  const [posParams, setPosParams] = useState({
    idCommerceDataMc: idCommerceDataMc,
    idTerminalMc: "",
    idSucursalMc: null,
    serialNumberMc: "",
    habilitado: true,
  });

  const handleChangeSerialNumber = (e) => {
    if (/^[a-zA-Z0-9 ]*$/.test(e.target.value)) {
      // Solo letras, números y espacios
      setPosParams({
        ...posParams,
        serialNumberMc: e.target.value.toUpperCase(),
      });
    }
  };
  const handleChangeIdTerminal = (e) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      setPosParams({ ...posParams, idTerminalMc: e.target.value });
    }
  };

  const validarDatos = () => {
    if (!posParams.idTerminalMc.trim() || !posParams.serialNumberMc.trim()) {
      setInputsError("Por favor, complete todos los campos obligatorios.");
      return false;
    }
    if (opcionSucursal.codigo === null) {
      setInputsError("Por favor, selecciona una Sucursal");
      return false;
    }
    return true;
  };

  const handleUnselectSucursal = async (props) => {
    setPosParams([]);
    onUnSelectOpcionSucursal();
  };

  const handleCrear = async () => {
    if (!validarDatos()) return;
    try {
      showLoader();
      const token = getToken();
      posParams.idSucursalMc = opcionSucursal.codigo;
      await crearPosDataMcRequest(posParams, token);
      showNotification("success", "Parámetros de POS creados con éxito");
      onChangeTabInternal(tabs.posTab);
    } catch (error) {
      console.error(error);
      showNotification("error", "Error al crear los parámetros de POS");
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    onObtenerSucursales(idCommerceDataMc);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        CREAR POS DATA
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: "2rem !important", minHeight: '400px' }}>
        <Grid container spacing={2} rowSpacing={2} xs={12} md={6}>
          <Grid item xs={12}>
            <label style={{ color: "black" }}>SELECCIONE SUCURSAL</label>
            <Combobox
              rows={opcionesSucursales}
              placeholder="Seleccione Sucursal"
              onFilter={() => {}}
              onSelect={onSelectOpcionSucursal}
              onUnSelect={handleUnselectSucursal}
              style={{ width: "200px" }}
              selected={opcionSucursal}
            />
          </Grid>
          <Grid item xs={12}>
            <label style={{ color: "black" }}>ID TERMINAL</label>
            <Input
              type="text"
              placeholder="ID Terminal"
              value={posParams.idTerminalMc}
              maxLength={8}
              onChange={handleChangeIdTerminal}
              style={{ width: "200px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <label style={{ color: "black" }}>SERIAL NUMBER</label>
            <Input
              type="text"
              placeholder="Número de Serie"
              value={posParams.serialNumberMc}
              maxLength={12}
              onChange={handleChangeSerialNumber}
              style={{ width: "200px" }}
            />
          </Grid>
          {inputsError && (
            <Grid item xs={12}>
              <p style={{ color: "red" }}>{inputsError}</p>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              style={{ marginRight: "10px" }}
              variant="gray"
              color="inherit"
              onClick={() => onChangeTabInternal(tabs.posTab)}
            >
              CANCELAR
            </Button>
            <Button color="primary" onClick={handleCrear}>
              ACEPTAR
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default CrearPosParamsDialogo;
