import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Combobox from "../../../Combobox";
import { Edit, Check, Close } from "@mui/icons-material";
import SucursalesParams from "./SucursalesParams";
import { parametrosIniciales } from "./commerceDefaultParams.js";
import Button from "../../../Button/Button.jsx";
import useSucursalesMc from "../../../../hooks/multicomercio/useSucursalesMc.jsx";
import useParametros from "../../../../hooks/multicomercio/parametros/useParametros.jsx";
import { isEmptyOrNullObject } from "../../../../utils/utilidades.js";
const SucursalesCommerceParams = ({
  idCommerceDataMc,
  nombreComercio,
  onChangeTabInternal,
}) => {
  const {
    selectedSucursal,
    opcionesSucursales,
    onObtenerSucursales,
    onSelectOpcionSucursal,
    onUnSelectOpcionSucursal,
  } = useSucursalesMc();

  const {
    parametros,
    onVaciarParametros,
    onSaveCommerceParams,
    onObtenerParametros,
  } = useParametros();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedParams, setEditedParams] = useState({});
  const handleSelectSucursal = async (opcion) => {
    onSelectOpcionSucursal(opcion);
  };

  const handleUnselectSucursal = () => {
    onUnSelectOpcionSucursal();
    onVaciarParametros();
  };

  const handleEditClick = () => {
    if (!isEditMode) {
      setEditedParams(parametros);
      setIsEditMode(true);
      return;
    }
  };

  const handleChange = (key, value) => {
    setEditedParams((prev) => ({ ...prev, [key]: value }));
  };

  const handleCancel = () => {
    setEditedParams(parametros[0]);
    setIsEditMode(false);
  };
  const handleEditarParametrosClick = () => {
    onSaveCommerceParams(editedParams);
  };
  const handleCrearParametrosInicialesClick = () => {
    onSaveCommerceParams({
      ...parametrosIniciales,
      idSucursalMc: selectedSucursal.idSucursalMc,
    });
  };
  useEffect(() => {
    if (isEmptyOrNullObject(selectedSucursal)) {
      onVaciarParametros();
      return;
    }
    onObtenerParametros(selectedSucursal.idSucursalMc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSucursal]);

  useEffect(() => {
    setIsEditMode(false);
  }, [parametros]);

  useEffect(() => {
    onObtenerSucursales(idCommerceDataMc);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>PARÁMETROS COMERCIO DE {nombreComercio}</span>
        {!isEmptyOrNullObject(parametros) && !isEditMode && (
          <div>
            <Tooltip title="Editar parámetros">
              <IconButton onClick={handleEditClick}>
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {isEditMode && (
          <div>
            <Tooltip title="Guardar cambios">
              <IconButton color="success" onClick={handleEditarParametrosClick}>
                <Check />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancelar cambios">
              <IconButton color="error" onClick={handleCancel}>
                <Close />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{ overflowY: "scroll !important", padding: "2rem !important" }}
      >
        <Typography variant="label">SELECCIONE UNA SUCURSAL</Typography>
        <Combobox
          rows={opcionesSucursales}
          placeholder="Seleccione Sucursal"
          onFilter={() => {}}
          onSelect={handleSelectSucursal}
          onUnSelect={handleUnselectSucursal}
          style={{ width: "200px" }}
        />
        <br />
        <br />
        <Renderizar 
        parametros={parametros}
        sucursal={selectedSucursal}
        onCrearParametrosInciales={handleCrearParametrosInicialesClick}
        isEditMode={isEditMode}
        onChangeSucursalParam={handleChange}
        />
      </DialogContent>
    </>
  );
};

function Renderizar({ parametros, sucursal, onCrearParametrosInciales, isEditMode, onChangeSucursalParam }) {
  console.log(parametros)
  if (isEmptyOrNullObject(sucursal)) {
    return <></>;
  }
  if (isEmptyOrNullObject(parametros)) {
    return (
      <>
        <Typography>No existen parametros en este comercio</Typography>
        <Button onClick={onCrearParametrosInciales}>
          Crear parametros
        </Button>
      </>
    );
  }
  return (
    <SucursalesParams
      parametros={parametros}
      isEditMode={isEditMode}
      onChange={onChangeSucursalParam}
    />
  );
}

export default SucursalesCommerceParams;
