import React, { useState } from "react";
import Input from '../Input'
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import {
    IconButton,
    TableCell,
    TableFooter,
    TableRow,
    Tooltip,
} from "@mui/material";

export default function EmailHandlerTableFooter({ onCrearEmail }) {
    const [value, setValue] = useState("");
    const handleInputChange = (e) => {
        setValue(e.target.value);
    };
    const handleCrearEmail = () => {
        onCrearEmail(value);
        setValue("");
    }
    return <TableFooter>
        <TableRow>
            <TableCell>
                <Input
                    type="text"
                    value={value}
                    onChange={handleInputChange}
                    placeholder="ejemplo@email.com"
                ></Input>
            </TableCell>
            <TableCell>
                <Tooltip title="Agregar correo">
                    <IconButton onClick={handleCrearEmail}>
                        <AddCircleOutlineSharpIcon
                            sx={{ width: "30px", height: "30px" }}
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    </TableFooter>
}