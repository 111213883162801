import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel, Typography } from "@mui/material";
import { SORT_DIRECTIONS } from "./XTable";

const validarDireccion = (direccionActual) => {
  if (direccionActual === SORT_DIRECTIONS.ASC) return SORT_DIRECTIONS.DESC;
  if (direccionActual === SORT_DIRECTIONS.DESC) {
    return SORT_DIRECTIONS.NONE;
  }
  return SORT_DIRECTIONS.ASC;
};

export default function XTableHeader({
  headerConfig,
  sortConfig,
  onSortChange,
}) {
  const handleSort = (element) => {
    const direccion =
      element.key === sortConfig.key
        ? validarDireccion(sortConfig.direction)
        : SORT_DIRECTIONS.ASC;
    onSortChange({
      key: element.key,
      direction: direccion,
    });
  };

  const handleSortClick = (element) => {
    if (element.sortable === false)
      return;
    handleSort(element);
  }

  return (
    <TableHead>
      <TableRow>
        {headerConfig.map((element, idx) => {
          const cellStyle = element.sx ? element.sx : {};
          if (element.sortable) cellStyle.cursor = "pointer";
          return (
            <TableCell
              key={idx}
              onClick={() => handleSortClick(element)}
              sx={cellStyle}
            >
              {element.sortable ? (
                <TableSortLabel
                  active={
                    sortConfig.key === element.key &&
                    sortConfig.direction !== SORT_DIRECTIONS.NONE
                  }
                  direction={sortConfig.direction || SORT_DIRECTIONS.ASC}
                >
                  <Typography>
                    <b>{element.label}</b>
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography>
                  <b>{element.label}</b>
                </Typography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
