import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import FiltroTexto from "../../components/FiltroTexto";
import { Link, useNavigate } from "react-router-dom";
import PromocionesTable from "../../components/PromocionesTable";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import Combobox from "../../components/Combobox";
import { getToken } from "../../connection/tokenController";
import { getIdComercio, isComercioUserRole} from "../../connection/loginController";
import { listarPromocionesRequest } from "../../connection/mantenedores/promocionesMantenedor";
import { useLoader } from "../../context/LoaderContext";

const estilos = {
  divRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
};

export const SELECTED_COMMERCE_PROMO = "promo_sel_comm";
export const PROMO_MANTENEDOR_INPAGE = "promo_man_in_page";

function parsearComboboxComercios(listado) {
  return listado
    .map((x) => ({
      codigo: x.idComercio,
      nombre: x.nombreComercio.toUpperCase(),
    }))
    .sort((a, b) => a.nombre.localeCompare(b.nombre));
}

const PromocionesMantenedor = () => {
  const { showLoader, hideLoader } = useLoader();
  const [textoFiltrar, setTextoFiltrar] = useState("");
  const [listado, setListado] = useState([]);
  const [idComercio, setIdComercio] = useState(getIdComercio() || "");
  const [comercios, setComercios] = useState([]);
  const [selectedComercio, setSelectedComercio] = useState(null);
  const navigate = useNavigate();
  const obtenerPromociones = async () => {
    if (!selectedComercio) return;
    showLoader();
    const token = getToken();
    try {
      const promociones = await listarPromocionesRequest(
        selectedComercio.codigo,
        token
      );
      setListado(promociones || []);
    } catch (error) {
      console.error("Error al obtener las promociones", error);
    }
    hideLoader();
  };

  const handleSelectComercio = (comercio) => {
    setIdComercio(comercio.codigo);
    setSelectedComercio(comercio);
    localStorage.setItem(SELECTED_COMMERCE_PROMO, JSON.stringify(comercio));
  };

  const handleChangeInputFilter = (texto) => {
    setTextoFiltrar(texto);
  };

  const handleUnselectComercio = () => {
    setIdComercio("");
    setSelectedComercio(null);
    setListado([]);
    localStorage.removeItem(SELECTED_COMMERCE_PROMO);
  };

  const handleEdit = (promocion) => {
    navigate(`/promociones/editar-promocion/${promocion.idPromocion}`, {
      state: { promocion, selectedComercio },
    });
  };

  const handleReports = (promocion) => {
    navigate(`/promociones/reportes/${promocion.idPromocion}`, {
      state: { promocion, idPromo: promocion.idPromo, idPromocion: promocion.idPromocion },
    });
  };

  useEffect(() => {
    const handleObtenerComercios = async () => {
      try {
        showLoader();
        const token = getToken();
        const response = await obtenerTodosLosComerciosRequest(token);
        setComercios(
          parsearComboboxComercios(response.filter((x) => x.habilitado))
        );
      } catch (error) {
        console.error("Error al obtener los comercios", error);
      } finally {
        hideLoader();
      }
    };

    handleObtenerComercios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setComercioInicial = () => {
    try {
      const inPage = localStorage.getItem(PROMO_MANTENEDOR_INPAGE);
      if (!inPage || inPage !== "true") return;

      const comercioSeleccionado = JSON.parse(
        localStorage.getItem(SELECTED_COMMERCE_PROMO)
      );

      if (comercioSeleccionado && comercioSeleccionado.codigo) {
        setSelectedComercio(comercioSeleccionado);
        setIdComercio(comercioSeleccionado.codigo);
      }
      localStorage.removeItem(PROMO_MANTENEDOR_INPAGE);
    } catch {}
  };

  useEffect(() => {
    setComercioInicial();
  }, [comercios]);

  useEffect(() => {
    if (selectedComercio) {
      obtenerPromociones();
    }
    // eslint-disable-next-line
  }, [selectedComercio]);

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        PROMOCIONES
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        {!isComercioUserRole() && (
          <>
            <div style={estilos.divRow}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label style={{ color: "black" }}>SELECCIONE COMERCIO</label>
                <Combobox
                  rows={comercios}
                  placeholder="Seleccione comercio"
                  value={selectedComercio}
                  onSelect={handleSelectComercio}
                  onUnSelect={handleUnselectComercio}
                  selected={selectedComercio}
                  style={{ width: "200px" }}
                />
              </div>
              <Link
                to="/promociones/crear-promocion"
                state={{ selectedComercio }}
                style={{ color: "black", textDecoration: "none" }}
              >
                <RoundButton variant="outlined">AGREGAR</RoundButton>
              </Link>
            </div>
            <br />
          </>
        )}
  
        <FiltroTexto
          type="text"
          onFilter={handleChangeInputFilter}
          style={{ visibility: selectedComercio ? "visible" : "hidden" }}
        />
        <br />
  
        <PromocionesTable
          listado={listado}
          onEdit={handleEdit}
          onReport={handleReports}
          idComercio={idComercio}
          filterText={textoFiltrar}
        />
      </ShadowBox>
    </>
  );
}

export default PromocionesMantenedor;
