import React, { useState } from "react";
import Dialogo from '../Dialogo';
import EmailHandlerTable from "./EmailHandlerTable";
import useEmailHandler from "../../hooks/emails/useEmailHandler";


export default function EmailHandler({ row, open }) {
    const { loading,
        correos,
        crearEmail,
        handleEditEmail,
        deleteEmail } = useEmailHandler({ open, comercio: row });
        
    const [dialogo, setDialogo] = useState({
        open: false,
        idEmailComercio: 0,
    });

    const handleConfirmDeleteEmail = (idEmailComercio) => {
        setDialogo({ idEmailComercio: idEmailComercio, open: true });
    };

    function handleDeleteEmail() {
        deleteEmail(dialogo.idEmailComercio);
    }
    const handleCloseDialog = () => {
        setDialogo({ ...dialogo, open: false });
    };
    return (
        <>
            <EmailHandlerTable loading={loading}
                correos={correos}
                onEditEmail={handleEditEmail}
                onDeleteEmail={handleConfirmDeleteEmail}
                onCrearEmail={crearEmail}
            />
            <Dialogo
                open={dialogo.open}
                onClose={handleCloseDialog}
                title="Eliminar Correo"
                text={"¿Estás seguro que deseas eliminar este correo electrónico?"}
                onConfirm={handleDeleteEmail}
            />
        </>
    )
}

