import React from "react";
import "./styles.css";
import ComerciosTableRow from "./ComerciosTableRow";
import XTable from "../XTable/XTable";
import { headerConfig } from "./ComercioTableHeaderConfig";
export default function ComerciosTable({ listado, onEdit, onAplication }) {
  return (
    <XTable
      headerConfig={headerConfig}
      data={listado}
      RowComponent={ComerciosTableRow}
      rowsPerPageOptions={[10, 20, 50]}
      events={[onEdit, onAplication]}
    />
  );
}
