import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Input from "../../../../Input/Input";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

export default function SucursalesMcTableRow({ row: sucursal, events }) {
  const [handleEditSucursal] = events;
  const [edit, setEdit] = useState(false);
  const [sucursalEdit, setSucursalEdit] = useState(sucursal);

  const handleChangeAtribute = (atributo, e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (value.length <= 30 && regex.test(value)) {
      setSucursalEdit((prev) => ({
        ...prev,
        [atributo]: value,
      }));
    }
  };
  const handleSave = () => {
    handleEditSucursal(sucursalEdit);
    setEdit(false);
  };
  const handleSetEdit = () => {
    setEdit(true);
  };
  const handleCancelEdit = () => {
    setSucursalEdit(sucursal);
    setEdit(false);
  };

  useEffect(() => {
    setSucursalEdit(sucursal);
  }, [sucursal]);

  return (
    <TableRow>
      <TableCell>
        <EditableField
          value={sucursalEdit.numeroSucursal}
          isEdit={edit}
          onChange={(e) => {
            handleChangeAtribute("numeroSucursal", e);
          }}
        />
      </TableCell>
      <TableCell>
        <EditableField
          value={sucursalEdit.nombreSucursal}
          isEdit={edit}
          onChange={(e) => {
            handleChangeAtribute("nombreSucursal", e);
          }}
        />
      </TableCell>
      <TableCell>
        <EditableField
          value={sucursalEdit.nombreCorto}
          isEdit={edit}
          onChange={(e) => {
            handleChangeAtribute("nombreCorto", e);
          }}
        />
      </TableCell>
      <TableCell>
        <EditableField
          value={sucursalEdit.direccionSucursal}
          isEdit={edit}
          onChange={(e) => {
            handleChangeAtribute("direccionSucursal", e);
          }}
        />
      </TableCell>
      <TableCell>
        <EditableField
          value={sucursalEdit.comunaSucursal}
          isEdit={edit}
          onChange={(e) => {
            handleChangeAtribute("comunaSucursal", e);
          }}
        />
      </TableCell>
      <ActionCell
        isEdit={edit}
        onSetEdit={handleSetEdit}
        onSave={handleSave}
        onCancel={handleCancelEdit}
      />
    </TableRow>
  );
}
function ActionCell({ isEdit, onSetEdit, onSave, onCancel }) {
  return (
    <TableCell>
      {isEdit ? (
        <>
          <Tooltip title="Guardar cambios" placement="left">
            <IconButton onClick={onSave}>
              <CheckIcon color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar edición" placement="right">
            <IconButton onClick={onCancel}>
              <CloseIcon color="error" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Editar sucursal" placement="left">
          <IconButton onClick={onSetEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
function EditableField({ value, isEdit, onChange }) {
  if (isEdit) {
    return (
      <>
        <Input value={value} onChange={onChange} />
      </>
    );
  }
  return <Typography>{value}</Typography>;
}
