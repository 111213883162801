import { useState } from "react";
import {opcionesTypes} from './opcionesParams'
import Input from "../../../Input/Input";

export default function CustomInput({ value, onChange, opciones, disabled }) {
    const [valor, setValor] = useState(value);
    const handleChange = (e) => {
      if (opciones.type !== opcionesTypes.ENTERO) {
        onChange(e.target.value);
        return;
      }
      try {
        if (e.target.value.length === 0) {
          setValor(opciones.min);
          onChange(`${opciones.min}`);
          return;
        }
        const numero = parseInt(e.target.value);
        if (numero < opciones.min) {
          setValor(opciones.min);
          onChange(`${opciones.min}`);
          return;
        }
        if (numero > opciones.max) {
          onChange(opciones.max);
          return;
        }
        setValor(numero);
        onChange(`${numero}`);
      } catch (error) {
        setValor(opciones.min);
        onChange(`${opciones.min}`);
        console.error(error);
      }
    };
  
    return (
      <Input value={valor} onChange={handleChange} style={{ width: "75%" }} disabled={disabled} />
    );
  }