import React from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BinesExpandedTableRow from "./BinesExpandedTableRow";

export const BinesExpandedTable = ({ row }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: "1rem 0" }}
    >
      <TableContainer sx={{ width: "auto" }}>
        <TableHead>
          <TableRow>
            {/* <TableCell>Marca</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Rango Desde</TableCell>
            <TableCell>Rango Hasta</TableCell>
            <TableCell>Código País</TableCell> */}
            <TableCell>Bines</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.bines.length === 0 ? (
            <TableRow>
              <TableCell colSpan={1} align="center">
                <Typography variant="body1" color="textSecondary">
                  No se encontraron bines.
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            row.bines.map((bin, idx) => {
              return <BinesExpandedTableRow row={bin} key={idx} />;
            })
          )}
        </TableBody>
      </TableContainer>
    </div>
  );
};
