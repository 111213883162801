import React from "react";
import XTable, { SORT_DIRECTIONS } from "../../../XTable/XTable";
import SucursalesMcTableRow from "./sucursalesMcTable/SucursalesMcTableRow";
import useSucursalesMcMantenedor from "../../../../hooks/multicomercio/sucursalesMc/useSucursalesMcMantenedor";
import { DialogContent, DialogTitle, Divider } from "@mui/material";
import RoundButton from "../../../RoundButton";
import { tabs } from "../SucursalesMcDialog";
import { compararASC } from "../../../../utils/utilidades";
const headerConfig = [
  {
    key: "numeroSucursal",
    label: "NÚMERO SUCURSAL",
    sortable: true,
    sortFn: (a, b, direccion) => {
      const result = compararASC(a, b, "numeroSucursal");
      return direccion === SORT_DIRECTIONS.ASC ? result : -result;
    },
    sx: { whiteSpace: "nowrap" },
  },
  {
    key: "nombreSucursal",
    label: "NOMBRE SUCURSAL",
    sortable: true,
  },
  {
    key: "nombreCorto",
    label: "NOMBRE CORTO",
    sortable: true,
  },
  {
    key: "direccionSucursal",
    label: "DIRECCIÓN",
    sortable: true,
  },
  {
    key: "comunaSucursal",
    label: "COMUNA",
    sortable: true,
  },
  { key: "", label: "ACCION", sortable: false, filter: false },
];
export default function SucursalesMcMantendor({
  idCommerceDataMc,
  nombreComercio,
  onChangeTabInternal,
}) {
  const { sucursales, handleEditSucursal } =
    useSucursalesMcMantenedor(idCommerceDataMc);
  const handleAgregarClick = () => {
    onChangeTabInternal(tabs.crearSucursalTab);
  };
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        SUCURSALES DE {nombreComercio}
        <RoundButton
          variant="outlined"
          color="primary"
          onClick={handleAgregarClick}
        >
          AGREGAR
        </RoundButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{padding: '2rem !important'}}>
        <XTable
          data={sucursales}
          RowComponent={SucursalesMcTableRow}
          events={[handleEditSucursal]}
          headerConfig={headerConfig}
          searchBar={true}
        />
      </DialogContent>
    </>
  );
}
