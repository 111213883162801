import React from "react";
import { Select, MenuItem } from "@mui/material";

export default function CustomSelect({ opciones, onChange, value, disabled }) {
  return (
    <Select
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      size="small"
      disabled={disabled}
      sx={{
        width: "auto",
        minWidth: "80%",
        maxWidth: "100px",
      }}
    >
      {opciones.map((opcion, idx) => (
        <MenuItem value={opcion.value} key={idx}>
          {opcion.label.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
}
