import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Skeleton,
} from "@mui/material";
import EmailHandlerTableRow from "./EmailHandlerTableRow";
import EmailHandlerTableFooter from "./EmailHandlerTableFooter";

export default function EmailHandlerTable({ loading, correos, onEditEmail, onDeleteEmail, onCrearEmail }) {
    return (
        <Table
            style={{
                width: "fit-content",
                borderSpacing: "0 1rem",
                fontSize: "1rem",
            }}
            size="small"
        >
            <TableHead>
                <TableRow>
                    <TableCell
                        style={{
                            padding: "0",
                            borderBottom: "1px solid #ddd",
                            textAlign: "center",
                        }}
                    >
                        CORREOS ELECTRÓNICOS
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Skeleton animation="pulse" />
                        </TableCell>
                    </TableRow>
                ) : (
                    correos.map((email, index) => (
                        <TableRow key={index} className="email-row">
                            <EmailHandlerTableRow
                                idEmailComercio={email.idEmailComercio}
                                email={email.email}
                                onEditEmail={onEditEmail}
                                onDeleteEmail={onDeleteEmail}
                                loading={loading}
                            />
                        </TableRow>
                    ))
                )}
            </TableBody>
            <EmailHandlerTableFooter onCrearEmail={onCrearEmail} />
        </Table>
    )
}

