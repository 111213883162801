export const headerConfig = [
    { key: "", label: "", style: { width: "1px", whiteSpace: "nowrap" } },
    {
      key: "numeroComercioGetnet",
      label: "N° COMERCIO",
      sortable: true,
      filter: true,
      sx: { width: "1px", whiteSpace: "nowrap" },
    },
    {
      key: "nombreComercio",
      label: "COMERCIO",
      sortable: true,
      filter: true,
      sx: { width: "1px", whiteSpace: "nowrap" },
    },
    {
      key: "credenciales",
      label: "CREDENCIALES",
      sortable: false,
      filter: false,
    },
    { key: "", label: "", sortable: false, filter: false },
    { key: "", label: "", sortable: false, filter: false },
  ];