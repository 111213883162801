import { getApiUrl, RequestContentType, getRequest, postRequest, putRequest, deleteRequest } from "../apiConnection";

const API_URL = getApiUrl();

export const crearCommerceDataMcRequest = async (objeto, token) => {
  const url = "/api/commercedatamc";
  return await postRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const listarCommerceDataMcRequest = async (idCommerceDataMc, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await getRequest(API_URL, url, token);
};

export const listarCommerceDataMcPorIdComercioRequest = async (idComercio, token) => {
  const url = `/api/commercedatamc?idComercio=${idComercio}`;
  return await getRequest(API_URL, url, token);
};
export const listarCommerceDataMcPorIdComercioHijoRequest = async (idComercio, token) => {
  const url = `/api/commercedatamc?idComerciohijo=${idComercio}`;
  return await getRequest(API_URL, url, token);
};

export const obtenerTodosLosPadresRequest = async (token) => {
  const url = "/api/comercios?mc=true";
  return await getRequest(API_URL, url, token);
};

export const obtenerHijosDePadreRequest = async (idComercioPadre, token) => {
  const url = `/api/comercios?idComercioPadre=${idComercioPadre}&mc=true`;
  return await getRequest(API_URL, url, token);
};

export const editarCommerceDataMcRequest = async (idCommerceDataMc, objeto, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const eliminarCommerceDataMcRequest = async (idCommerceDataMc, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await deleteRequest(API_URL, url, token);
};

export const listarPadresCommerceDataMcRequest = async (token) => {
  const url = `/api/comercios?listaPadres=true`;
  return await getRequest(API_URL, url, token);
};

export const listarHijosCommerceDataMcRequest = async (token) => {
  const url = `/api/comercios?listaHijos=true`;
  return await getRequest(API_URL, url, token);
};

// SUCURSALES MC

export const crearSucursalMcRequest = async (objeto, token) => {
  const url = "/api/sucursalesmc";
  return await postRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const listarSucursalMcRequest = async (idSucursalMc, token) => {
  const url = `/api/sucursalesmc/${idSucursalMc}`;
  return await getRequest(API_URL, url, token);
};

export const listarSucursalesMcPorIdCommerceDataRequest = async (idCommerceDataMc, token) => {
  const url = `/api/sucursalesmc?idCommerceDataMc=${idCommerceDataMc}`;
  return await getRequest(API_URL, url, token);
};

export const listarSucursalesMcPorIdSucrusalMcRequest = async (idSucursalMc, token) => {
  const url = `/api/sucursalesmc?idSucursalMc=${idSucursalMc}`;
  return await getRequest(API_URL, url, token);
};

export const editarSucursalMcRequest = async (idSucursalMc, objeto, token) => {
  const url = `/api/sucursalesmc/${idSucursalMc}`;
  return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const eliminarSucursalMcRequest = async (idSucursalMc, token) => {
  const url = `/api/sucursalesmc/${idSucursalMc}`;
  return await deleteRequest(API_URL, url, token);
};

// COMMERCE PARAMS MC

export const crearCommerceParamsMcRequest = async (body, token) => {
  const url = `/api/commerceparamsmc`;
  return await postRequest(API_URL, url,RequestContentType.APPLICATION_JSON, body, token);
};

export const listarCommerceParamsPorSucursalRequest = async (idSucursalMc, token) => {
  const url = `/api/commerceparamsmc?idSucursalMc=${idSucursalMc}`;
  return await getRequest(API_URL, url, token);
} 
export const editarcommerceparamsmcMcRequest = async (idCommerceParams, body, token) => {
  const url = `/api/commerceparamsmc/${idCommerceParams}`;
  return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, body, token);
};


// POS PARAMS MC 

export const crearPosDataMcRequest = async (body, token) => {
  const url = `/api/posdatamc`;
  return await postRequest(API_URL, url, RequestContentType.APPLICATION_JSON, body, token);
};

export const listarPosDataMcPorIdRequest = async (idPosDataMc, token) => {
  const url = `/api/posdatamc/${idPosDataMc}`;
  return await getRequest(API_URL , url, token);
};

export const listarPosDataMcPorIdCommerceRequest = async (idCommerceDataMc, token) => {
  const url = `/api/posdatamc?idCommerceDataMc=${idCommerceDataMc}`;
  return await getRequest(API_URL, url, token);
};
export const listarPosDataMcPorIdSucursalMcRequest = async (idSucursalMc, token) => {
  const url = `/api/posdatamc?idSucursalMc=${idSucursalMc}`;
  return await getRequest(API_URL, url, token);
};
export const editarPosDataMcRequest = async (idPosDataMc, body, token) => {
  const url = `/api/posdatamc/${idPosDataMc}`;
  return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, body, token);
};

export const eliminarPosDataMcRequest = async (idPosDataMc, token) => {
  const url = `/api/posdatamc/${idPosDataMc}`;
  return await deleteRequest(API_URL, url, token);
};



