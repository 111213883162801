import React, { useEffect } from 'react'
import Transacciones from '../../components/Transacciones'
import { obtenerTxsC2CRequest } from '../../connection/mantenedores/transacciones';
import { getToken } from '../../connection/tokenController';
import { useLoader } from '../../context/LoaderContext';
import { isLoginComercio } from '../../connection/loginController';
import { APP_C2C, obtenerAppsHabilitadas } from '../../connection/AppsController';
import { useNavigate } from 'react-router-dom';
const listaComandos = [
    { nombre: "\u00A099 | OBTENER TXS DESDE EL POS", codigo: 99 },
    { nombre: "100 | VENTA ", codigo: 100 },
    { nombre: "102 | ANULACIÓN", codigo: 102 },
    { nombre: "103 | CIERRE", codigo: 103 },
    { nombre: "105 | DETALLE DE VENTAS", codigo: 105 },
    { nombre: "106 | CONEXIÓN", codigo: 106 },
    { nombre: "108 | DEVOLUCIÓN", codigo: 108 },
    { nombre: "109 | DUPLICADO", codigo: 109 },
    { nombre: "117 | SERVICIO DE IMPRESIÓN", codigo: 117 },
    { nombre: "126 | VENTA PROMOCIONAL", codigo: 126 },
  ];
export default function C2CTransacciones() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const navigate = useNavigate()
    
    const handleBuscar = async (idPos, fechaDesde, fechaHasta, comandos) => {
        return new Promise(async (resolve, reject) => {
            try {
                showLoader();
                const respuesta = await obtenerTxsC2CRequest(
                    idPos,
                    fechaDesde,
                    fechaHasta,
                    comandos,
                    getToken()
                );
                const listado = respuesta.map((item) => {
                    return {
                        idTxs: item.idPosTxs,
                        comando: item.comando,
                        fechaRequest: new Date(item.fechaRequest),
                        functionCode: item.functionCode,
                        responseCode: item.responseCode,
                        responseMessage: item.responseMessage,
                        fechaResponse:
                            new Date(item.fechaResponse),
                        request: item.request,
                        response: item.response,
                    };
                });
                resolve(listado);
            } catch (error) {
                resolve([]);
                if (error.status === 500) {
                    showNotification("error", "Error de conexión");
                    return;
                }
                showNotification("error", "Ha ocurrido un error inesperado");
            }finally {
                hideLoader();
            }
        })
    }
    useEffect(() =>{
        checkearValidRoute()
        // eslint-disable-next-line
    },[])

    
    const checkearValidRoute = async () =>  {
        if (!isLoginComercio()) return;
        const nombresApps = await obtenerAppsHabilitadas();
        if (!nombresApps.includes(APP_C2C)) {
          navigate('/404');
        }
      }
    return (
        <Transacciones 
        titulo="G-SERVICES" 
        onBuscar={handleBuscar} 
        variant="c2c"
        comandos={listaComandos} />
    )
}
