import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import XTableHeader from "./XTableHeader";
import FiltroTexto from "../FiltroTexto";
import { TableCell, TableRow, Typography } from "@mui/material";
export const SORT_DIRECTIONS = {
  ASC: "asc",
  DESC: "desc",
  NONE: "",
};

export default function XTable({
  headerConfig,
  data,
  RowComponent,
  events,
  searchBar = true,
  pagination = true,
  rowsPerPageOptions = [10, 25, 50],
  sx = {},
}) {
  const [filtroTexto, setFiltroTexto] = useState("");
  const [showableListado, setShowableListado] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: rowsPerPageOptions[0],
    count: 1,
    page: 0,
  });

  // Función para manejar el cambio de página
  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  // Función para manejar el cambio de filas por página
  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: e.target.value,
    });
  };

  const inicioCorte = () => {
    return paginationControl.page * paginationControl.rowsPerPage;
  };
  const corteFinal = () => {
    return (
      paginationControl.rowsPerPage * paginationControl.page +
      paginationControl.rowsPerPage
    );
  };

  const handleSort = (sortConfig) => {
    setSortConfig(sortConfig);
  };
  // Función para filtrar los datos según el texto de búsqueda
  const sortData = (listado) => {
    if (
      sortConfig.key === null ||
      sortConfig.direction === SORT_DIRECTIONS.NONE
    ) {
      return listado; // Si no hay ordenamiento, devolvemos el orden original
    }
    const sortElement = headerConfig.find(x => x.key === sortConfig.key);
    if (sortElement.sortFn) {
      return [...listado].sort((a, b) => sortElement.sortFn(a, b, sortConfig.direction));
    }
    return [...listado].sort((a, b) => {
      if (sortConfig.key === null) return 0; // Si no hay ordenamiento, devolvemos el orden original

      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Ordenamiento por valores primitivos (como strings o números)
      if (typeof aValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      if (aValue < bValue)
        return sortConfig.direction === SORT_DIRECTIONS.ASC ? -1 : 1;
      if (aValue > bValue)
        return sortConfig.direction === SORT_DIRECTIONS.ASC ? 1 : -1;
      return 0;
    });
  };

  // Función para ordenar los datos según la configuración actual
  const filterData = (listado) => {
    if (filtroTexto.length === 0) {
      return listado;
    }
    return data.filter((row) => {
      return headerConfig
        .filter((column) => column.filter !== false) // Filtrar solo las columnas donde filter es distinto de false
        .some((column) => {
          if (column.filterFn) {
            // Si hay una función de filtro personalizada, la usamos
            return column.filterFn(row[column.key], filtroTexto);
          }
          if (typeof row[column.key] === "string") {
            return row[column.key]
              .toLowerCase()
              .includes(filtroTexto.toLowerCase());
          }
          if (typeof row[column.key] === "number") {
            return row[column.key]
              .toString()
              .includes(filtroTexto.toString().toLowerCase());
          }
          return false;
        });
    });
  };
  const handleFilter = (value) => {
    setFiltroTexto(value);
  };
  const renderData = () => {
    const filtrado = filterData(data);
    const ordenado = sortData(filtrado);
    setShowableListado(ordenado);
  };

  useEffect(() => {
    renderData();
    // eslint-disable-next-line
  }, [data, sortConfig, filtroTexto]);

  return (
    <div>
      {/* Barra de búsqueda */}
      {searchBar && (
        <FiltroTexto
          type="text"
          placeholder="Buscar..."
          onFilter={handleFilter}
        />
      )}
      {/* Tabla */}
      <TableContainer>
        <Table sx={sx}>
          <XTableHeader
            headerConfig={headerConfig}
            sortConfig={sortConfig}
            onSortChange={handleSort}
          />
          <XTableBody
            listado={showableListado}
            inicioCorte={inicioCorte()}
            corteFinal={corteFinal()}
            headerConfig={headerConfig}
            events={events}
            RowComponent={RowComponent}
          />
        </Table>
        {/* Paginación */}
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={showableListado.length}
            rowsPerPage={paginationControl.rowsPerPage}
            page={paginationControl.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        )}
      </TableContainer>
    </div>
  );
}
function XTableBody({
  listado,
  inicioCorte,
  corteFinal,
  headerConfig,
  events,
  RowComponent,
}) {
  return (
    <TableBody>
      {listado.length === 0 ? (
        <TableRow>
          <TableCell colSpan={headerConfig.length} sx={{ textAlign: "center" }}>
            <Typography>No hay datos</Typography>
          </TableCell>
        </TableRow>
      ) : (
        listado
          .slice(inicioCorte, corteFinal)
          .map((row, index) => (
            <RowComponent
              key={index}
              row={row}
              headerConfig={headerConfig}
              events={events}
            />
          ))
      )}
    </TableBody>
  );
}
