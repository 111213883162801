import { IconButton, Skeleton, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import Input from '../Input';
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EmailHandlerTableRow({ idEmailComercio, email, loading, onEditEmail, onDeleteEmail }) {
    const [isEditing, setIsEditing] = useState(false);

    function handleEditEmail(value) {
        onEditEmail(idEmailComercio, value);
        setIsEditing(false);
    }
    function handleCancelEditing() {
        setIsEditing(false);
    }
    function handleDeleteEmail() {
        onDeleteEmail(idEmailComercio);

    }
    if (loading) {
        return (
            <TableRow>
                <TableCell colSpan={2}>
                    <Skeleton animation="pulse" />
                </TableCell>
            </TableRow>
        );
    }
    if (isEditing)
        return (
            <EditableRow originalValue={email} onEditEmail={handleEditEmail} onCancelEdit={handleCancelEditing} />
        );
    return (
        <>
        
            <TableCell>
                {loading ? (
                    <Skeleton animation="pulse" />
                ) : (
                    <span>{email.toUpperCase()}</span>
                )}
            </TableCell>
            <TableCell>
                <Tooltip title="Editar">
                    <IconButton onClick={() => setIsEditing(true)}>
                        <EditIcon style={{ cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Eliminar">
                    <IconButton onClick={handleDeleteEmail}>
                        <DeleteIcon style={{ cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </>
    );
}


function EditableRow({ originalValue, onEditEmail, onCancelEdit }) {
    const [value, setValue] = useState(originalValue);
    const handleChangeValue = (e) => {
        setValue(e.target.value);
    }
    const handleEditEmail = () => {
        onEditEmail(value);
    }
    const handleCancelEditing = () => { 
        onCancelEdit();
        setValue(originalValue);
    }
    return (
        <>
            <TableCell>
                <Input
                    type="text"
                    value={value}
                    onChange={handleChangeValue}
                    placeholder="ejemplo@email.com"
                />
            </TableCell>
            <TableCell>
                <Tooltip title="Aceptar">
                    <IconButton style={{ color: "green" }} onClick={handleEditEmail}>
                        <CheckIcon style={{ cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Cancelar">
                    <IconButton style={{ color: "red" }} onClick={handleCancelEditing}>
                        <Close style={{ cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </>
    );
}
