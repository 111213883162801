import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
  Switch,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BinesExpandedTable } from "./BinesExpandedTable/BinesExpandedTable.jsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useLoader } from "../../context/LoaderContext";
import TinyCalendario from "../../components/PromocionesTable/TinyCalendario.jsx";
import { editarPromocionRequest } from "../../connection/mantenedores/promocionesMantenedor.js";
import { isComercioUserRole } from "../../connection/loginController";
import { getToken } from "../../connection/tokenController.js";
import AssessmentIcon from '@mui/icons-material/Assessment';

const formatTope = (topes) => {
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  }).format(topes);
};

const PromocionesTableRow = ({
  item,
  onEdit,
  onReport,
  toggleApp,
  open,
  onOpen,
  idComercio,
}) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [habilitado, setHabilitado] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSwitchChange = useCallback(() => {
    if (habilitado) {
      setOpenDialog(true);
    } else {
      handleConfirmChange();
    }
    // eslint-disable-next-line
  }, [habilitado]);

  const handleConfirmChange = async () => {
    const newStatus = !habilitado;

    try {
      showLoader();
      const token = getToken();
      const objeto = { ...item, habilitado: newStatus };
      const idPromocion = item.idPromocion;
      const response = await editarPromocionRequest(idPromocion, objeto, token);
      if (response.ok) {
        showNotification("success", "Modificado exitosamente");

        if (typeof toggleApp === "function") {
          await toggleApp(item);
        }
        setHabilitado(newStatus);
      } else {
        showNotification("error", "Error al actualizar la promoción");
      }
    } catch (error) {
      console.error("Error al actualizar el estado de la promoción:", error);
      showNotification("error", "Error al conectar con el servidor");
    } finally {
      hideLoader();
      setOpenDialog(false);
    }
  };

  const handleCancelChange = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    const savedStatus = item?.habilitado;
    setHabilitado(savedStatus);
  }, [item.idPromo, item.habilitado]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
        <Tooltip title="Reportes">
            <IconButton
              aria-label="Reportes"
              onClick={() => onReport(item, item.idPromocion, item.idPromo, item.cantidad)}
            >
              <AssessmentIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography>{item.idPromo.toUpperCase()}</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "left" }}>
          {new Date(item.vigenciaDesde).toLocaleDateString("es-CL")}
          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {new Date(item.vigenciaDesde).toLocaleTimeString("es-CL")}
          </div>
        </TableCell>
        <TableCell sx={{ textAlign: "left" }}>
          {new Date(item.vigenciaHasta).toLocaleDateString("es-CL")}
          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {new Date(item.vigenciaHasta).toLocaleTimeString("es-CL")}
          </div>
        </TableCell>

        <TableCell>{item.porcentajeDescuento}%</TableCell>
        <TableCell>
          {item.topes ? formatTope(item.topes) : "Sin tope"}
        </TableCell>
        <TableCell>{item.cantidad}</TableCell>
        <TableCell>
          <TinyCalendario calendario={item.calendario} />
        </TableCell>

        {!isComercioUserRole() && (
          <TableCell
            sx={{ padding: "0px 0.5rem", whiteSpace: "nowrap", width: "1px" }}
          >
            <Switch
              checked={habilitado}
              onChange={handleSwitchChange}
              color="primary"
            />
            <Tooltip title="Editar">
              <IconButton
                style={{ marginLeft: "10px" }}
                aria-label="Editar"
                onClick={() => onEdit(item)}  
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell colSpan={10} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <BinesExpandedTable row={item} idComercio={idComercio} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog open={openDialog} onClose={handleCancelChange}>
        <DialogTitle>Deshabilitar</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro en deshabilitar esta Promocion?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange}>Cancelar</Button>
          <Button onClick={handleConfirmChange}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromocionesTableRow;
