import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import ComerciosTable from "../../components/ComerciosTable";
import useComercioMantendor from "../../hooks/comercios/useComerciosMantenedor";
import RoundButton from "../../components/RoundButton";

const ComerciosMantenedor = () => {
  let navigate = useNavigate();

  const { listado } = useComercioMantendor();

  const handleEditComercio = (comercio) => {
    navigate("/comercios/editar-comercio", { state: { comercio: comercio } });
  };
  const handleAplication = (idComercio) => {
    navigate(`/comercios/aplicaciones/${idComercio}`);
  };
  const handleCreateComercio = () => {
    navigate(`/comercios/nuevo-comercio`);
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        COMERCIOS
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", overflow: "auto" }}>
          <RoundButton variant="outlined" onClick={handleCreateComercio}>
            AGREGAR
          </RoundButton>
        </div>
        <ComerciosTable
          listado={listado}
          onEdit={handleEditComercio}
          onAplication={handleAplication}
          onCreate={handleCreateComercio}
        />
      </ShadowBox>
    </>
  );
};

export default ComerciosMantenedor;
