import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import BusinessIcon from "@mui/icons-material/Business";

const MultiComerciosHijosRow = ({ row, events }) => {
  const [onDesvincularHijo, handleOpenDialogSucursales] = events;
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleConfirmDelete = () => {
    if (row.idCommerceDataMc) {
      onDesvincularHijo(row.idCommerceDataMc);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row.numeroComercioGetnet}</TableCell>
        <TableCell>{row.nombreComercio.toUpperCase()}</TableCell>
        <TableCell>{row.rut}</TableCell>
        <TableCell
          sx={{ padding: "0px 0.5rem", whiteSpace: "nowrap", width: "1px" }}
        >
          <Tooltip title="Desvincular Comercio" placement="left">
            <IconButton onClick={handleOpenDialog}>
              <LinkOffIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sucursales" placement="right">
            <IconButton onClick={() => handleOpenDialogSucursales(row)}>
              <BusinessIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle id="confirm-delete-dialog">
          Confirmar eliminación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas desvincular el comercio{" "}
            <b>{row.nombreComercio}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleConfirmDelete}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiComerciosHijosRow;
