import { useEffect, useState } from 'react'
import { useLoader } from '../../context/LoaderContext';
import { getToken } from '../../connection/tokenController';
import { obtenerComercioPorIdRequest, obtenerTodosLosComerciosRequest } from '../../connection/mantenedores/comercioMantenedor';
import { getIdComercio, isComercioUserRole } from '../../connection/loginController';

export default function useComercioMantendor() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const [listado, setListado] = useState([]);
    
    const onlyComercio = async () => {
        try {
            const token = getToken();
            const onlyComercio = await obtenerComercioPorIdRequest(getIdComercio(), token);
            setListado([onlyComercio]);
        } catch (error) {
            showNotification("error", "Ha ocurrido un error de conexión");
        }
    };
    const fetchComercios = async () => {
        try {
            showLoader();
            if (isComercioUserRole()) {
                return onlyComercio();
            }
            const token = getToken();
            let listadoComercios = await obtenerTodosLosComerciosRequest(token);
            listadoComercios = listadoComercios.filter((x) => x.habilitado);
            listadoComercios = listadoComercios.sort((a, b) => {
                if (a.nombreComercio < b.nombreComercio) return -1;
                if (a.nombreComercio > b.nombreComercio) return 1;
                return 0;
            });
            const ordenListado = listadoComercios.map(x => { return { ...x, nombreComercio: x.nombreComercio.toUpperCase() } })
            setListado(ordenListado);
        } catch (error) {
            console.error("Error al obtener los comercios:", error);
            if (error.status === 500) {
                showNotification("error", "Error de conexión");
            } else {
                showNotification("error", "Ha ocurrido un error inesperado");
            }
        } finally {
            hideLoader();
        }
    }
    useEffect(() => {
        fetchComercios();
        // eslint-disable-next-line
    }, [])
    return { listado, fetchComercios };
}
