import React from "react";
import {
  Paper,
  TableContainer,
} from "@mui/material";
import MulticomercioTableRow from "./MulticomercioTableRow";
import XTable from "../XTable/XTable";
import { mcPadresHeaderConfig } from "./multiComercioTableHeaderConfig";

export default function MulticomercioTable(props) {
  const {
    listado,
    onAddComercioHijo,
    onDesvincularHijo,
    onOpenComercioData,
    onOpenDialogSucursales
  } = props;
  return (
    <TableContainer component={Paper}>
      <XTable 
      headerConfig={mcPadresHeaderConfig} 
      RowComponent={MulticomercioTableRow}
      data={listado}
      rowsPerPageOptions={[10, 20, 50]}
      events={[onOpenComercioData, onAddComercioHijo, onDesvincularHijo, onOpenDialogSucursales]}
      />
    </TableContainer>
  );
}