import React, { useEffect, useState } from "react";
import { useLoader } from "../../context/LoaderContext";
import { getToken } from "../../connection/tokenController";
import { listarSucursalesMcPorIdCommerceDataRequest } from "../../connection/mantenedores/multicomercioMantenedor";
import { compararASC } from "../../utils/utilidades";
const parsearSucursales = (listado = []) => {
  return listado.map(({ idSucursalMc: codigo, nombreSucursal: nombre }) => ({
    codigo,
    nombre: nombre.toUpperCase(),
  }));
};
export default function useSucursalesMc(stateName) {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listadoSucursales, setListadoSucursales] = useState([]);
  const [opcionesSucursales, setOpcionesSucursales] = useState([]);
  const [opcionSucursal, setOpcionSucursal] = useState({});
  const [selectedSucursal, setSelectedSucursal] = useState({});

  async function onObtenerSucursales(idCommerceDataMc) {
    if (!idCommerceDataMc) return;
    try {
      showLoader();
      const token = getToken();
      const respuesta = await listarSucursalesMcPorIdCommerceDataRequest(
        idCommerceDataMc,
        token
      );
      const ordenados = respuesta.sort((a, b) =>
        compararASC(a, b, "nombreSucursal")
      );
      setListadoSucursales(ordenados);
      setOpcionesSucursales(parsearSucursales(ordenados));
    } catch (error) {
      showNotification("error", "Error al obtener las sucursales");
      console.error("Error al obtener las sucursales:", error);
      setListadoSucursales([]);
      setOpcionesSucursales([]);
    } finally {
      hideLoader();
    }
  }

  const onSelectOpcionSucursal = (opcion) => {
    if (!opcionesSucursales.find(x => x.codigo === opcion.codigo))
      return;
    setOpcionSucursal(opcion);
    const sucursal = listadoSucursales.find(
      (x) => x.idSucursalMc === opcion.codigo
    );
    setSelectedSucursal(sucursal);
    if (stateName) {
      localStorage.setItem(stateName, JSON.stringify(opcion));
    }
  };

  const onUnSelectOpcionSucursal = () => {
    setOpcionSucursal({});
    setSelectedSucursal({});
    if (stateName) {
      localStorage.setItem(stateName, "");
    }
  };

  const obtenerSucursalSeleccionadaPreviamente = () => {
    const seleccionado = localStorage.getItem(stateName);
    try {
      const opcion = JSON.parse(seleccionado);
      if (opcion) {
        onSelectOpcionSucursal(opcion);
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    if (stateName && listadoSucursales.length > 0) obtenerSucursalSeleccionadaPreviamente();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listadoSucursales]);

  return {
    selectedSucursal,
    opcionSucursal,
    listadoSucursales,
    opcionesSucursales,
    onObtenerSucursales,
    onSelectOpcionSucursal,
    onUnSelectOpcionSucursal,
  };
}
