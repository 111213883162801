import React from "react";
import { Grid, Typography } from "@mui/material";
import { paramsCommerce } from "./opcionesParams";

export default function SucursalesParams({
  parametros = {},
  isEditMode,
  onChange,
  style,
}) {
  return (
    <Grid container spacing={3} columnSpacing={3} sx={style}>
      {paramsCommerce.map((element) => (
        <Grid item xs={12} sm={6} md={4} key={element.atributeName}>
          <RenderParam
            label={element.label.toUpperCase()}
            isEditMode={isEditMode}
            onChange={(value) => onChange(element.atributeName, value)}
            value={parametros[element.atributeName]}
            Componente={element.componente}
            opciones={element.opciones}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function RenderParam({
  label,
  isEditMode,
  Componente,
  value,
  onChange,
  opciones,
}) {
  const findOpcion = () => {
    if (Array.isArray(opciones)) {
      const opcion = opciones.find((x) => x.value === value);
      return opcion ? opcion.label.toUpperCase() : '';
    }
    return value;
  };
  const estilos = {
    border: "1px solid rgba(0, 0, 0, 0.28)",
    padding: "8px 12px",
    borderRadius: "4px",
    width: "75%",
    color: "#00000088",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '12px'
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" color="textSecondary">
        <strong>{label}:</strong>
      </Typography>
      {!isEditMode && (
        <Typography variant="body1" sx={estilos}>
          &nbsp;{findOpcion()}
        </Typography>
      )}
      {isEditMode && (
        <Componente value={value} onChange={onChange} opciones={opciones} />
      )}
    </div>
  );
}
