import React, { useState } from "react";
import { DialogTitle, DialogContent, Grid, Divider } from "@mui/material";
import Input from "../../../Input/Input";
import { useLoader } from "../../../../context/LoaderContext";
import { crearCommerceParamsMcRequest, crearSucursalMcRequest } from "../../../../connection/mantenedores/multicomercioMantenedor";
import { getToken } from "../../../../connection/tokenController";
import Button from "../../../Button";
import { tabs } from "../SucursalesMcDialog";
import { parametrosIniciales } from "../../SucursalesTable/sucursalesParams/commerceDefaultParams";
export default function CrearSucursalDialogo({
  idCommerceDataMc,
  onChangeTabInternal,
  nombreComercio,
}) {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [inputsError, setInputsError] = useState("");
  const [sucursal, setSucursal] = useState({
    idCommerceDataMc: idCommerceDataMc,
    numeroSucursal: "",
    nombreCorto: "",
    NombreSucursal: "",
    comunaSucursal: "",
    direccionSucursal: "",
    habilitado: true,
  });

  const handleVolver = (e) => {
    e.preventDefault();
    onChangeTabInternal(tabs.sucursalesTab);
  };

  const handleChange = (field, value) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (value.length <= 60 && regex.test(value)) {
      setSucursal({ ...sucursal, [field]: value });
    }
  };

  const validarDatos = () => {
    if (
      !sucursal.direccionSucursal.trim() ||
      !sucursal.numeroSucursal.trim() ||
      !sucursal.NombreSucursal.trim() ||
      !sucursal.nombreCorto.trim() ||
      !sucursal.comunaSucursal.trim()
    ) {
      setInputsError("Por favor, complete todos los campos obligatorios.");
      return false;
    }
    return true;
  };

  const handleCrear = async () => {
    if (!validarDatos()) return;
    try {
      showLoader();
      const token = getToken();
      const respuesta = await crearSucursalMcRequest({ ...sucursal }, token);
      showNotification("success", "Sucursal creada con éxito");
      handleCrearParams(respuesta.idSucursalMc)
    } catch (error) {
      showNotification("error", "Error al crear la sucursal");
    } finally {
      hideLoader();
    }
  };
  const handleCrearParams = async (idSucursalMc) => {
    if (!validarDatos()) return;
    try {
      showLoader();
      const token = getToken();
      parametrosIniciales.idSucursalMc = idSucursalMc;
      const respuesta = await crearCommerceParamsMcRequest(parametrosIniciales, token);
      onChangeTabInternal(tabs.sucursalesTab);
    } catch (error) {
      showNotification("error", "Error al crear los parametros");
    } finally {
      hideLoader();
    }
  }
  return (
    <>
      <DialogTitle>CREAR SUCURSAL EN {nombreComercio}</DialogTitle>
      <Divider />
      <DialogContent sx={{padding: '2rem !important'}}>
        <form>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            item
            xs={12}
            md={6}
          >
            <Grid item xs={12} sm={6} spacing={0}>
              <label style={{ color: "black" }}>NOMBRE SUCURSAL</label>
              <Input
                type="text"
                placeholder="Nombre Sucursal"
                value={sucursal.NombreSucursal}
                maxLength={60}
                style={{ width: "calc(100% - 1rem)" }}
                onChange={(e) => handleChange("NombreSucursal", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ color: "black" }}>NOMBRE CORTO</label>
              <Input
                type="text"
                placeholder="Nombre Corto"
                value={sucursal.nombreCorto}
                maxLength={22}
                style={{ width: "calc(100% - 1rem)" }}
                onChange={(e) => handleChange("nombreCorto", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ color: "black" }}> NÚMERO DE SUCURSAL</label>
              <Input
                type="text"
                placeholder="Número de la Sucursal"
                value={sucursal.numeroSucursal}
                maxLength={15}
                style={{ width: "calc(100% - 1rem)" }}
                onChange={(e) => handleChange("numeroSucursal", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ color: "black" }}> DIRECCIÓN</label>
              <Input
                type="text"
                placeholder="Dirección"
                value={sucursal.direccionSucursal}
                maxLength={60}
                style={{ width: "calc(100% - 1rem)" }}
                onChange={(e) =>
                  handleChange("direccionSucursal", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ color: "black" }}>COMUNA</label>
              <Input
                type="text"
                placeholder="Comuna"
                value={sucursal.comunaSucursal}
                maxLength={60}
                style={{ width: "calc(100% - 1rem)" }}
                onChange={(e) => handleChange("comunaSucursal", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ marginRight: "10px" }}
                variant="gray"
                color="inherit"
                onClick={handleVolver}
              >
                CANCELAR
              </Button>
              <Button color="primary" onClick={handleCrear}>
                ACEPTAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
}
