import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ShadowBox from "../ShadowBox";
import RoundButton from "../RoundButton";
import TransaccionesTableRow from "../TransaccionesTable/TransaccionesTableRow";
import Combobox from "../Combobox";
import SelectorFecha from "../SelectorFecha";
import TransaccionDialog from "../TransaccionDialog";
import ChipList from "../ChipList";
import useMcTransacciones from "../../hooks/transacciones/useMcTransacciones";
import XTable from "../XTable/XTable";
import { headerConfig } from "./headerConfig.js";
const TransaccionesMc = ({ titulo, variant, comandos: listadoComandos }) => {
  const {
    comerciosPadres,
    comerciosHijos,
    sucursalesMc,
    listadoPos,
    fechas,
    comandosSeleccionados,
    transacciones,
    idComercioHijoSelected,
    idComercioPadreSelected,
    idSucursalMcSelected,
    idPosDataSelected,
    seleccionarComercioPadre,
    seleccionarComercioHijo,
    seleccionarSucursalMc,
    seleccionarPos,
    seleccionarFechaDesde,
    seleccionarFechaHasta,
    handleBuscarTransacciones,
    handleSelectComando,
    handleDeleteComando,
  } = useMcTransacciones();
  const [dialogoJson, setDialogoJson] = useState({
    open: false,
    titulo: "",
    request: "",
    response: "",
  });

  const handleSelectComercioPadre = (row) => {
    seleccionarComercioPadre(row.codigo);
  };

  const handleUnselectComercioPadre = () => {
    seleccionarComercioPadre(0);
  };

  const handleSelectComercioHijo = (row) => {
    seleccionarComercioHijo(row.codigo);
  };

  const handleUnselectComercioHijo = () => {
    seleccionarComercioHijo(0);
  };

  const handleSelectSucursal = (row) => {
    seleccionarSucursalMc(row.codigo);
  };

  const handleUnselectSucursal = () => {
    seleccionarSucursalMc(0);
  };

  const handleSelectPos = (row) => {
    seleccionarPos(row.codigo);
  };
  const handleUnselectPos = () => {
    seleccionarPos(0);
  };

  const handleSetFechaDesde = (fecha) => {
    seleccionarFechaDesde(fecha);
  };

  const handleSetFechaHasta = (fecha) => {
    seleccionarFechaHasta(fecha);
  };

  const handleBuscarClick = () => {
    handleBuscarTransacciones();
  };

  const handleShowRequest = (row) => {
    try {
      let request;
      let response;
      if (variant === "donaciones") {
        request = JSON.parse(row.request);
        response = row.response ? JSON.parse(row.response) : "";
      }
      if (variant === "c2c" || variant === "gscan") {
        const json = JSON.parse(row.request);
        request = JSON.parse(json.JsonSerialized);
        response = row.response ? JSON.parse(row.response) : "";
      }
      if (variant === "multicomercio") {
        request = JSON.parse(row.request);
        response = row.response ? JSON.parse(row.response) : "";
      }
      setDialogoJson({
        open: true,
        titulo: "Request",
        request: request ? JSON.stringify(request, null, 2) : null,
        response: response ? JSON.stringify(response, null, 2) : null,
      });
    } catch {
      setDialogoJson({
        open: true,
        titulo: "Request",
        request: "",
        response: "",
      });
    }
  };

  const handleCloseDialogoJson = () => {
    setDialogoJson({ ...dialogoJson, open: false });
  };
  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        TRANSACCIONES DE {titulo || "POR POS"}
      </Typography>
      <ShadowBox style={{ padding: "1rem 2rem" }}>
        <Grid container spacing={2} item md={12} lg={6}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>
                SELECCIONE COMERCIO PADRE
              </label>
              <Combobox
                rows={comerciosPadres}
                placeholder="Seleccione comercio"
                onFilter={() => {}}
                onSelect={handleSelectComercioPadre}
                onUnSelect={handleUnselectComercioPadre}
                style={{ width: "200px" }}
                selected={
                  idComercioPadreSelected <= 0
                    ? {}
                    : comerciosPadres.find(
                        (x) => x.codigo === idComercioPadreSelected
                      )
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>SELECCIONE COMERCIO HIJO</label>
              <Combobox
                rows={comerciosHijos}
                placeholder="Seleccione comercio"
                onFilter={() => {}}
                onSelect={handleSelectComercioHijo}
                onUnSelect={handleUnselectComercioHijo}
                style={{ width: "200px" }}
                selected={
                  idComercioHijoSelected <= 0
                    ? {}
                    : comerciosHijos.find(
                        (x) => x.codigo === idComercioHijoSelected
                      )
                }
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid container item spacing={2} md={12} lg={6}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>SELECCIONE SUCURSAL</label>
              <Combobox
                rows={sucursalesMc}
                placeholder="Seleccione sucursal"
                onFilter={() => {}}
                onSelect={handleSelectSucursal}
                onUnSelect={handleUnselectSucursal}
                style={{ width: "200px" }}
                selected={
                  idSucursalMcSelected <= 0
                    ? {}
                    : sucursalesMc.find(
                        (x) => x.codigo === idSucursalMcSelected
                      )
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>SELECCIONE POS</label>
              <Combobox
                rows={listadoPos}
                placeholder="Seleccione pos"
                onFilter={() => {}}
                onSelect={handleSelectPos}
                onUnselect={handleUnselectPos}
                style={{ width: "200px" }}
                selected={
                  idPosDataSelected <= 0
                    ? {}
                    : listadoPos.find((x) => x.codigo === idPosDataSelected)
                }
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid container item spacing={2} md={12} lg={6}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>FECHA DESDE</label>
              <SelectorFecha
                label="Fecha Desde"
                value={fechas.fechaDesde}
                onChange={handleSetFechaDesde}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label style={{ color: "black" }}>FECHA HASTA</label>
              <SelectorFecha
                label="Fecha Hasta"
                value={fechas.fechaHasta}
                onChange={handleSetFechaHasta}
                min={fechas.fechaDesde}
                max={fechas.maxDate}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <div>
          <label style={{ color: "black" }}>SELECCIONE COMANDO</label>
          <Combobox
            rows={listadoComandos}
            placeholder="Comando..."
            onFilter={() => {}}
            onSelect={handleSelectComando}
            disableSelection={true}
            style={{ width: "200px" }}
          />
        </div>
        <br />
        {comandosSeleccionados.length > 0 && (
          <ChipList
            listado={comandosSeleccionados}
            label={(x) => `${x.nombre}`}
            onDelete={handleDeleteComando}
          />
        )}
        <br />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <RoundButton variant="outlined" onClick={handleBuscarClick}>
            BUSCAR
          </RoundButton>
        </div>
        <br />
        <XTable
          headerConfig={headerConfig}
          data={transacciones}
          RowComponent={TransaccionesMcRow}
          events={[handleShowRequest]}
          searchBar={false}
        />
        <TransaccionDialog
          titulo={dialogoJson.titulo}
          request={dialogoJson.request}
          response={dialogoJson.response}
          open={dialogoJson.open}
          onClose={handleCloseDialogoJson}
        />
      </ShadowBox>
    </>
  );
};

export default TransaccionesMc;

function TransaccionesMcRow({ row, events }) {
  const [onOpenRequests] = events;
  return (
    <TransaccionesTableRow
      fechaRequest={row.fechaRequest}
      fechaResponse={row.fechaResponse}
      idTxs={row.idMcTxs}
      comando={row.comando}
      functionCode={row.functionCode}
      responseCode={row.responseCode}
      responseMessage={row.responseMessage}
      onShowRequest={() => onOpenRequests(row)}
    />
  );
}
