import React, { useEffect, useRef, useState } from "react";
import { MenuItem } from "@mui/material";
import "./styles.css";
import FiltroTexto from "../FiltroTexto/FiltroTexto";

export default function Combobox(props) {
  const refContainer = useRef(null);
  const refMenu = useRef(null);

  const emptyText = props.emptyText || "No hay datos";
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [listado, setListado] = useState([]);
  const [selected, setSelected] = useState({
    codigo: -1,
    nombre: "",
  });

  const handleOpen = () => {
    setValue("");
    setOpen(true);
  };
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };
  const handleSelect = (row) => {
    if (!props.disableSelection)
      setSelected(row);
    if (props.onSelect) props.onSelect(row);
  };
  const handleFilter = (texto) => {
    if (texto.trim() === "") {
      setListado(props.rows);
    } else {
      const filteredList = props.rows.filter((item) =>
        item.nombre.toLowerCase().includes(texto.toLowerCase())
      );
      setListado(filteredList);
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleUnSelect = (e) => {
    e.preventDefault();
    setSelected({
      codigo: -1,
      nombre: "",
    });
    if (props.onUnSelect) props.onUnSelect();
  };
  const represent = () => {
    setValue(selected.nombre);
  };

  useEffect(() => {
    represent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  

  useEffect(() => {
    if (open === false) {
      represent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setListado(props.rows);
  }, [props.rows]);

  useEffect(() => {
    if (!props.selected) {
      setSelected({
        codigo: -1,
        nombre: "",
      });
      return;
    }
    if (props.selected.codigo === -1) {
      setSelected({
        codigo: -1,
        nombre: "",
      });
      return;
    }
    setSelected(props.selected)
  }, [props.selected]);

  return (
    <>
      <FiltroTexto
        variant="combobox"
        type="text"
        placeholder={props.placeholder || "Buscar..."}
        onFocus={handleOpen}
        onBlur={handleClose}
        onFilter={handleFilter}
        value={value}
        onChange={handleChange}
        selected={selected.codigo > -1}
        onUnSelect={handleUnSelect}
        style={props.style || null}
      />
      <div ref={refContainer} className="combobox_content_container">
        <div ref={refMenu} className={"combobox_content " + (open ? "combobox_visible" : "")}>
          {listado.length === 0 ? (
            <MenuItem value={0} disabled>
              {emptyText}
            </MenuItem>
          ) : (
            listado.map((row, idx) => {
              return (
                <MenuItem
                  value={row.codigo}
                  key={idx}
                  onClick={() => {
                    handleSelect(row);
                  }}
                >
                  {row.nombre}
                </MenuItem>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}
