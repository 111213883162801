export function validarRut(rut) {
    try {
        if (!rut) {
            return false;
        }
        if (rut.length < 1) {
            return false;
        }
        rut = rut.replace(/\./g, "").toUpperCase();
        let dv = rut.slice(-1);
        let rutSinDv = rut.slice(0, -1);
        let rutTemp = rutSinDv.split('-');
        if (isNaN(parseInt(rutTemp)))
            throw new Error("Ingrese Rut válido");

        if (rutTemp.length < 2) {
            rutTemp = [rutSinDv, dv];
        }

        if (dv !== digito(parseInt(rutTemp[0].length > 0 ? rutTemp[0] : "-1"))) {
            return false;
        }
        if (rutTemp[0].length > 8) {
            return false;
        }
        return true;
    } catch (e) {
        throw new Error("Ingrese Rut válido");
    }
}

function digito(rut) {
    let suma = 0;
    let multiplicador = 1;
    while (rut !== 0) {
        multiplicador++;
        if (multiplicador === 8) multiplicador = 2;
        suma += (rut % 10) * multiplicador;
        rut = Math.floor(rut / 10);
    }
    suma = 11 - (suma % 11);
    if (suma === 11) {
        return "0";
    } else if (suma === 10) {
        return "K";
    } else {
        return suma.toString();
    }
}

export function formatearRut(rut) {
    rut = rut.replace(/\./g, "").toUpperCase();
    let dv = rut.slice(-1);
    let rutSinFormato = rut.replace('-', '').slice(0, -1);
    return `${rutSinFormato}-${dv}`;
}

export function validarURL(url) {
    const patron = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
    return patron.test(url);
}

export function validarTextoNumero(texto) {
    try {
        let dato = parseInt(texto);

        if (isNaN(dato) || dato === 0)
            dato = "";
        return dato;
    } catch {
        return "";
    }
}
function compareASC(a, b) {
    if (typeof(a) === "string" && typeof(b) === "string") {
        a = a.toLowerCase();
        b = b.toLowerCase();
    }
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}
function compareDESC(a, b) {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
}

export function compararASC(a, b, nombreAtributo) {
    return compareASC(a[nombreAtributo], b[nombreAtributo]);
}
export function compararDESC(a, b, nombreAtributo) {
    return compareDESC(a[nombreAtributo], b[nombreAtributo]);
}
export function compararFechasASC(a, b) {
    return compareASC(a, b);
}
export function compararFechasDESC(a, b) {
    return compareDESC(a, b);
}
const extraerRut = (rut) => {
    return rut.replace(/-/g, '')
}
export function compararRutASC(rut1, rut2) {
    const nRut1 = parseInt(extraerRut(rut1));
    const nRut2 = parseInt(extraerRut(rut2));
    return compareASC(nRut1, nRut2)
}
export function compararRutDESC(rut1, rut2) {
    const nRut1 = parseInt(extraerRut(rut1));
    const nRut2 = parseInt(extraerRut(rut2));
    return compareDESC(nRut1, nRut2)
}

export function validarEmail(text) {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(text);
}
export function validarNombre(nombre) {
    const nombreregex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]*$/;
    return nombreregex.test(nombre);
}  
export function obtenerNumeroAleatorio(min, max) {
    return Math.round(Math.random() * (max - min) + min)
}
export function isProduction() {
    return false;
    // return process.env.REACT_APP_AMBIENTE === "prod";
}

export function parsearCombobox(listado, atributoCodigo, atributoNombre) {
    return listado.map((element) => {
        const nombre = `${element[atributoNombre]}`;
        return {
            nombre: nombre.toUpperCase(),
            codigo: element[atributoCodigo],
        };
    });
}

export function capitalizarPrimeraLetra(string) {
    return string.split('.').map(oracion => {
        const texto = oracion.trim();
        return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
    }).join('. ');
}

export function cutString(original = "", maxLenght = 0) {
    if (original.length <= maxLenght)
        return original;
    return `${original.substring(0, maxLenght)}...`;
}
export function isEmptyOrNullObject(objeto) {
    if (objeto === null)
        return true;
    return Object.keys(objeto).length === 0;
}