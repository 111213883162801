import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import {
  obtenerTodosLosPadresRequest,
  eliminarCommerceDataMcRequest,
  obtenerHijosDePadreRequest,
} from "../../connection/mantenedores/multicomercioMantenedor";
import { useLoader } from "../../context/LoaderContext";
import { getToken } from "../../connection/tokenController";
import MulticomercioTable from "../../components/MulticomercioTable";
import useSucursalesMcDialog from "../../hooks/multicomercio/sucursalesMc/useSucursalesMcDialog";
import { compararASC } from "../../utils/utilidades";
import SucursalesMcDialog from "../../components/MulticomercioTable/dialogoSucursalesMc/SucursalesMcDialog";

export const MULTICOMERCIO_MANTENEDOR_INPAGE = "multicomercio_man_in_page";

const MultiComerciosMantenedor = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listado, setListado] = useState([]);
  const { 
    dialogData: sucursalMcDialog, 
    handleClose: handleCloseSucursalMcDialog, 
    handleOpen: handleOpenSucursalMcDialog 
  } = useSucursalesMcDialog();

  const handleAddComercioHijoClick = (row) => {
    const estado = {
      comercioPadre: row,
    };
    navigate(`/multi-comercios/editar-multi-comercios/${row.idComercio}`, {
      state: { multiComercio: estado },
    });
  };

  const handleDesvincularComercioHijoClick = async (idCommerceDataMc) => {
    try {
      await eliminarCommerceDataMcRequest(idCommerceDataMc, getToken());
      showNotification("success", "Comercio desvinculado correctamente");
      obtenerDatosMulticomercio();
    } catch (error) {
      console.error("Error al eliminar comercio:", error.message);
      showNotification(
        "error",
        "No se puede eliminar dado que el registro tiene relaciones con otras tablas. Contactar a iOneTech."
      );
    }
  };

  const obtenerDatosMulticomercio = async () => {
    try {
      showLoader();
      const token = getToken();
      const respuesta = await obtenerTodosLosPadresRequest(token);
      if (!respuesta || !Array.isArray(respuesta)) {
        throw new Error("Datos inválidos del endpoint");
      }
      const prevComerciosHijosOpened = listado.filter(
        (x) => x.comerciosHijos.open
      );

      const nuevoListado = await Promise.all(
        respuesta.map(async (x) => {
          const comercio = prevComerciosHijosOpened.find(
            (y) => y.idComercio === x.idComercio
          );
          if (!comercio) {
            x["comerciosHijos"] = { comercios: [], open: false };
            return x;
          }
          let comerciosHijos = [];
          if (comercio && comercio.comerciosHijos.open) {
            comerciosHijos = await obtenerComerciosHijos(x.idComercio);
            comerciosHijos = comerciosHijos.sort((a, b) => compararASC(a, b, "nombreComercio"));
          }
          const open = comercio ? comercio.comerciosHijos.open : false;
          x["comerciosHijos"] = { comercios: comerciosHijos, open: open };
          return x;
        })
      );

      setListado(nuevoListado.sort((a, b) => {
        return compararASC(a, b, "nombreComercio");
      }));
    } catch (error) {
      console.error("Error al obtener datos del multicomercio:", error.message);
      showNotification("error", "Error al cargar datos del comercio");
    } finally {
      hideLoader();
    }
  };

  async function obtenerComerciosHijos(idComercio) {
    try {
      const token = getToken();
      return await obtenerHijosDePadreRequest(idComercio, token);
    } catch (error) {
      // controlar errores
    }
  }

  async function handleOpenCommerceRow(data) {
    try {
      if (data.comerciosHijos.open) {
        handleCloseCommerceRow(data);
        return;
      }
      let comerciosHijos = await obtenerComerciosHijos(data.idComercio);
      comerciosHijos = comerciosHijos.sort((a, b) => compararASC(a, b, "nombreComercio"));
      const nuevoListado = [...listado];
      const objeto = nuevoListado.find((x) => x.idComercio === data.idComercio);
      objeto["comerciosHijos"] = {
        comercios: comerciosHijos,
        open: true,
      };
      setListado(nuevoListado);
    } catch (error) {
      // controlar error
    }
  }
  const handleCloseCommerceRow = (data) => {
    const nuevoListado = [...listado];
    const objeto = nuevoListado.find((x) => x.idComercio === data.idComercio);
    objeto.comerciosHijos.open = false;
    setListado(nuevoListado);
  };

  const handleOpenDialogSucursales = (comercio) => {
    localStorage.setItem(
      "nombreComercio",
      comercio.nombreComercio.toUpperCase()
    );
    handleOpenSucursalMcDialog(comercio.idCommerceDataMc);
  };

  useEffect(() => {
    obtenerDatosMulticomercio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        MULTI-COMERCIOS
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            to="/multi-comercios/crear-multi-comercios"
            style={{ color: "black", textDecoration: "none" }}
          >
            <RoundButton variant="outlined">AGREGAR</RoundButton>
          </Link>
        </div>
        <br />
        <br />
        <MulticomercioTable
          listado={listado}
          onAddComercioHijo={handleAddComercioHijoClick}
          onDesvincularHijo={handleDesvincularComercioHijoClick}
          onOpenComercioData={handleOpenCommerceRow}
          onOpenDialogSucursales={handleOpenDialogSucursales}
        />
      </ShadowBox>
       <SucursalesMcDialog
        open={sucursalMcDialog.open}
        onClose={handleCloseSucursalMcDialog}
        idCommerceDataMc={sucursalMcDialog.idCommerceDataMc}
      />
    </>
  );
};

export default MultiComerciosMantenedor;
