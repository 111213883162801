import React, { useEffect, useState } from "react";
import { DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import RoundButton from "../../../RoundButton";
import ListarPosParams from "./ListarPosParams";
import {
  editarPosDataMcRequest,
  listarPosDataMcPorIdSucursalMcRequest,
} from "../../../../connection/mantenedores/multicomercioMantenedor";
import { useLoader } from "../../../../context/LoaderContext";
import { getToken } from "../../../../connection/tokenController";
import Combobox from "../../../Combobox";
import { tabs } from "../../dialogoSucursalesMc/SucursalesMcDialog";
import useSucursalesMc from "../../../../hooks/multicomercio/useSucursalesMc";
export const SELECTED_SUCURSAL_KEY = "posdatamc_selected_sucursal";

const PosDataMc = ({
  idCommerceDataMc,
  nombreComercio,
  onChangeTabInternal,
}) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [posData, setPosData] = useState([]);
  const {
    selectedSucursal,
    opcionSucursal,
    opcionesSucursales,
    onObtenerSucursales,
    onSelectOpcionSucursal,
    onUnSelectOpcionSucursal
  } = useSucursalesMc(SELECTED_SUCURSAL_KEY);


  const handleEditarPos = async (pos) => {
    try {
      showLoader();
      const token = getToken();
      const response = await editarPosDataMcRequest(
        pos.idPosDataMc,
        pos,
        token
      );
      if (response.status >= 200 || response.status < 300) {
        handleObtenerPosData(selectedSucursal.idSucursalMc, pos.numeroSucursal);
      } else {
        throw new Error("Error desconocido al actualizar el estado.");
      }
    } catch (err) {
      showNotification(
        "error",
        err.message || "Error al cambiar el estado del POS."
      );
    } finally {
      hideLoader();
    }
  };
  async function handleObtenerPosData(idSucursalMc, numeroSucursal) {
    if (!idSucursalMc)
      return;
    try {
      showLoader();
      const token = getToken();

      const response = await listarPosDataMcPorIdSucursalMcRequest(
        idSucursalMc,
        token
      );
      const ordenados = response
        .sort((a, b) => a.idPosDataMc - b.idPosDataMc)
        .map((posData) => ({
          ...posData,
          numeroSucursal: numeroSucursal,
        }));

      setPosData(ordenados);
    } catch (err) {
      console.error(
        "Error al obtener los datos de PosParams:",
        err.message || err
      );
      showNotification("error", err.message || "Error al obtener datos.");
    } finally {
      hideLoader();
    }
  }

  const handleUnSelectSucursal = () => {
    onUnSelectOpcionSucursal();
    setPosData([]);
  }

  useEffect(() => {
    onObtenerSucursales(idCommerceDataMc);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleObtenerPosData(selectedSucursal.idSucursalMc, selectedSucursal.numeroSucursal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSucursal]);


  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        POS DE {nombreComercio}
        <RoundButton
          variant="outlined"
          color="primary"
          onClick={() => onChangeTabInternal(tabs.crearPosTab)}
        >
          AGREGAR
        </RoundButton>
      </DialogTitle>
      <Divider />
      <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
        <Typography variant="label">SELECCIONE UNA SUCURSAL</Typography>
        <Combobox
          rows={opcionesSucursales}
          placeholder="Seleccione Sucursal"
          onSelect={onSelectOpcionSucursal}
          onUnSelect={handleUnSelectSucursal}
          style={{ width: "200px" }}
          selected={opcionSucursal}
        />
      </div>
      <DialogContent  sx={{padding: '2rem !important'}}>
        <ListarPosParams
          idCommerceDataMc={idCommerceDataMc}
          posData={posData}
          handleObtenerPosData={handleObtenerPosData}
          selectedIdSucursal={selectedSucursal.idSucursalMc}
          onEditPos={handleEditarPos}
        />
      </DialogContent>
    </>
  );
};

export default PosDataMc;
