import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  Switch,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import Input from "../../../Input/Input";
import { Check, Close } from "@mui/icons-material";
import XTable from "../../../XTable/XTable";
const headerConfig = [
  {
    key: "numeroSucursal",
    label: "NÚMERO SUCURSAL",
    sortable: true,
    sx: { whiteSpace: "nowrap" },
  },
  {
    key: "idTerminalMc",
    label: "ID TERMINAL",
    sortable: true,
  },
  {
    key: "serialNumberMc",
    label: "SERIAL NUMBER",
    sortable: true,
  },
  { key: "", label: "ACCION", sortable: false, filter: false },
];

export default function ListarPosParams({ posData, onEditPos }) {
  const handleEditPos = (pos) => {
    onEditPos(pos);
  };

  const handleToggleHabilitar = async (pos) => {
    pos.habilitado = !pos.habilitado;
    onEditPos(pos);
  };
  return (
    <>
      <XTable
        headerConfig={headerConfig}
        data={posData}
        RowComponent={EditableRow}
        events={[handleToggleHabilitar, handleEditPos]}
        searchBar={true}
      />
    </>
  );
}
const EditableRow = ({ row: originalItem, events }) => {
  const [handleToggleHabilitar, handleEditPos] = events;
  const [elemento, setElemento] = useState(originalItem);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setElemento(originalItem);
    setIsEdit(false)
  }, [originalItem]);

  function onCancel() {
    setElemento(originalItem);
    setIsEdit(false);
  }

  function onSuccess() {
    handleEditPos(elemento);
    setIsEdit(false);
  }

  return (
    <TableRow>
      <TableCell>
        <Typography sx={{ 
          color: isEdit ? "#00000060" : "#000000", 
          fontStyle: (isEdit ? "italic" : "normal")
          }}>
          {originalItem.numeroSucursal}
        </Typography>
      </TableCell>
      <TableCell>
        <EditableField
          value={elemento.idTerminalMc}
          maxLength={8}
          onChange={(valor) =>
            setElemento({ ...elemento, idTerminalMc: valor })
          }
          isEdit={isEdit}
        />
      </TableCell>
      <TableCell>
        <EditableField
          value={elemento.serialNumberMc}
          maxLength={12}
          onChange={(valor) =>
            setElemento({ ...elemento, serialNumberMc: valor })
          }
          isEdit={isEdit}
        />
      </TableCell>

      <TableCell
        sx={{
          padding: "0px 0.5rem",
          whiteSpace: "nowrap",
          width: "1px",
        }}
      >
        {
          !isEdit && <Switch
          checked={elemento.habilitado}
          onChange={() => handleToggleHabilitar(elemento)}
          color="primary"
        />
        }
        
        {!isEdit && (
          <Tooltip title="Editar" placement="right">
            <IconButton aria-label="Editar" onClick={() => setIsEdit(!isEdit)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {isEdit && (
          <>
            <Tooltip title="Guardar cambios" placement="left">
              <IconButton onClick={onSuccess}>
                <Check color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar edición" placement="right">
              <IconButton onClick={onCancel}>
                <Close color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};
const EditableField = ({
  value: originalValue,
  onChange,
  isEdit = false,
  maxLength,
}) => {
  const [value, setValue] = useState(originalValue);

  function handleChange(e) {
    const inputValue = e.target.value;

    if (inputValue.length <= maxLength && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setValue(inputValue);
      onChange(inputValue, e);
    }
  }
  useEffect(() => {
    setValue(originalValue);
    // eslint-disable-next-line
  }, [isEdit]);

  return (
    <>
      {!isEdit ? (
        <Typography>{originalValue}</Typography>
      ) : (
        <Input completeWidth={true} value={value} onChange={handleChange} />
      )}
    </>
  );
};
