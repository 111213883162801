import { useLoader } from "../../context/LoaderContext";
import { envioCredencialesRequest } from "../../connection/mantenedores/comercioMantenedor";
import { getToken } from "../../connection/tokenController";

export default function useEmailSender() {
  const { showLoader, hideLoader, showNotification } = useLoader();

  const sendEmails = async (idComercio) => {
    showLoader();
    try {
      const send = await envioCredencialesRequest(idComercio, getToken());
      if (send) {
        showNotification("success", "¡Credenciales enviadas!");
      }
    } catch (error) {
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
    }
  };
  return {sendEmails};
}
