import { useEffect, useState } from 'react'
import { useLoader } from '../../context/LoaderContext';
import { getToken } from '../../connection/tokenController';
import { crearEmailRequest, editarEmailRequest, eliminarEmailRequest, obtenerEmailsRequest } from '../../connection/mantenedores/comercioMantenedor';

export default function useEmailHandler({ open, comercio }) {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const [loading, setLoading] = useState(true)
    const [correos, setCorreos] = useState([]);
    async function callEmails() {
        setLoading(true);
        const correos = await obtenerEmailsRequest(comercio.idComercio, getToken());
        const correosHabiles = correos.filter(
            (object) => object.habilitado === true
        );
        const correosOrden = correosHabiles.sort((a, b) =>
            a.email.localeCompare(b.email)
        );
        setCorreos(correosOrden);
        setLoading(false);
    }
    async function crearEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            showNotification("error", "Correo electrónico no válido");
            return;
        }
        try {
            const body = {
                idComercio: comercio.idComercio,
                email: email,
                habilitado: comercio.habilitado,
            };
            showLoader();
            const res = await crearEmailRequest(body, getToken());
            if (res.idEmailComercio) {
                showNotification(
                    "success",
                    "Correo electrónico creado exitosamente!"
                );
                callEmails();
            }
            return;
        } catch (error) {
            showNotification("error", "Algo salió mal");
        } finally {
            hideLoader();
        }
    }

    const handleEditEmail = async (idEmailComercio, nuevoEmail) => {
        showLoader();
        const body = {
            idComercio: comercio.idComercio,
            email: nuevoEmail,
            habilitado: comercio.habilitado,
        };
        try {
            const res = await editarEmailRequest(body, idEmailComercio, getToken());
            if (res) {
                showNotification("success", "Correo electrónico editado exitosamente");
                callEmails();
            }
        } catch (error) {
            showNotification("error", "Algo salió mal");
        } finally {
            hideLoader();
        }
    };

    const deleteEmail = async (idEmailComercio) => {
        showLoader();
        try {
            const res = await eliminarEmailRequest(
                idEmailComercio,
                getToken()
            );
            if (res) {
                showNotification(
                    "success",
                    "Correo electrónico eliminado exitosamente!"
                );
                callEmails();
            }
        } catch (error) {
            showNotification("error", "Algo salió mal");
        } finally {
            hideLoader();
        }
    };
    useEffect(() => {
        if (open) callEmails();
        // eslint-disable-next-line
    }, [open]);
    return {
        loading,
        correos,
        crearEmail,
        handleEditEmail,
        deleteEmail
    }
}
