import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import Combobox from "../../components/Combobox";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import { useLoader } from "../../context/LoaderContext";
import { isProduction } from "../../utils/utilidades";
import { getToken } from "../../connection/tokenController";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import {
  crearCommerceDataMcRequest,
  listarHijosCommerceDataMcRequest,
  listarPadresCommerceDataMcRequest,
  obtenerTodosLosPadresRequest,
} from "../../connection/mantenedores/multicomercioMantenedor";
import Papa from "papaparse";

const breadcrumbItems = [
  { href: "/multi-comercios", label: "Multi Comercios" },
];

export default function EditarCrearMultiComercios() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [isEdit, setIsEdit] = useState(false);
  const [inputsError] = useState("");
  const [multiComercio, setMultiComercio] = useState({
    idComercioPadre: "",
    idComercioHijo: "",
    allowedBines: "",
    restrictedBines: "",
    habilitado: true,
  });
  const [comercioPadre, setComercioPadre] = useState(null);
  const [comercioHijo, setComercioHijo] = useState(null);
  const [comerciosPadres, setComerciosPadres] = useState([]);
  const [comerciosHijos, setComerciosHijos] = useState([]);
  const [binPermitido, setBinPermitido] = useState(null);
  const [binRestringido, setBinRestringido] = useState(null);
  let location = useLocation();
  const inputFilePermitido = useRef(null);
  const inputFileRestringido = useRef(null);

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/multi-comercios");
  };

  const handleFileUpload = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile({
          name: file.name,
          content: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const decodeBase64 = (base64String) => {
    const decodedString = atob(base64String.split(",")[1]);
    return decodedString;
  };

  const parseCSV = (csvData) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        complete: (result) => {
          const parsedBines = result.data.map((item, index) => ({
            key: index,
            rangoDesde: item["Rango Desde"],
            rangoHasta: item["Rango Hasta"],
            marca: item["Marca"],
            tipo: item["Tipo"],
            pais: item["Pais"],
            codigoPais: item["Codigo Pais"],
          }));
          resolve(parsedBines);
        },
        error: (error) => reject(error),
      });
    });
  };

  const handleSetComercios = async () => {
    try {
      showLoader();
      const token = getToken();
      const listadoComercios = await obtenerTodosLosComerciosRequest(token);
      if (!listadoComercios || !Array.isArray(listadoComercios)) {
        throw new Error("Error: No se encontraron comercios.");
      }
      const comercioOptions = listadoComercios
        .filter((x) => x.habilitado)
        .map((x) => ({
          nombre: x.nombreComercio.toUpperCase(),
          codigo: x.idComercio,
        }))
        .sort((a, b) => a.nombre.localeCompare(b.nombre));
      setComerciosPadres(comercioOptions);
      setComerciosHijos(comercioOptions);
    } catch (error) {
      showNotification("error", "Error al cargar comercios");
    } finally {
      hideLoader();
    }
  };

  const handleSetComerciosPadres = async () => {
    try {
      showLoader();
      const token = getToken();
      const comerciosDisponibles = await listarPadresCommerceDataMcRequest(token);
      const padres = await obtenerTodosLosPadresRequest(token);
      const listadoFinal = comerciosDisponibles
      .filter((com) => !padres.find(padre => com.idComercio === padre.idComercio));

      const sortedPadres = listadoFinal
        .map((x) => ({
          nombre: x.nombreComercio.toUpperCase(),
          codigo: x.idComercio,
          disabled: false,
        }))
        .sort((a, b) => a.nombre.localeCompare(b.nombre));
      setComerciosPadres(sortedPadres);
    } catch (error) {
      showNotification("error", "Error al cargar comercios padres");
    } finally {
      hideLoader();
    }
  };

  const handleSetComerciosHijos = async () => {
    try {
      showLoader();
      const token = getToken();
      const hijos = await listarHijosCommerceDataMcRequest(token);
      const sortedHijos = hijos
        .map((x) => ({
          nombre: x.nombreComercio.toUpperCase(),
          codigo: x.idComercio,
          disabled: false,
        }))
        .sort((a, b) => a.nombre.localeCompare(b.nombre));
      setComerciosHijos(sortedHijos);
    } catch (error) {
      showNotification("error", "Error al cargar comercios hijos");
    } finally {
      hideLoader();
    }
  };

  const handleComercioPadreChange = (row) => {
    setComercioPadre(row);
    setComerciosHijos((prev) =>
      prev
        .map((comercio) => ({
          ...comercio,
          disabled: comercio.codigo === row?.codigo,
        }))
        .filter((comercio) => comercio.codigo !== row?.codigo)
    );
  };

  const handleComercioHijoChange = (row) => {
    setComercioHijo(row);
    setComerciosPadres((prev) =>
      prev
        .map((comercio) => ({
          ...comercio,
          disabled: comercio.codigo === row?.codigo,
        }))
        .filter((comercio) => comercio.codigo !== row?.codigo)
    );
  };

  const comerciosHijosFiltrados = comercioPadre
    ? comerciosHijos.filter(
        (comercio) => comercio.codigo !== comercioPadre.codigo
      )
    : comerciosHijos;

  const handleCrear = async () => {
    const token = getToken();
    const objeto = {
      ...multiComercio,
      idComercioPadre: comercioPadre?.codigo || "",
      idComercioHijo: comercioHijo?.codigo || "",
      allowedBines: binPermitido
        ? await parseCSV(decodeBase64(binPermitido.content))
        : [],
      restrictedBines: binRestringido
        ? await parseCSV(decodeBase64(binRestringido.content))
        : [],
      habilitado: true,
    };
    try {
      showLoader();
      await crearCommerceDataMcRequest(objeto, token);
      showNotification("success", "Multi Comercio creado con éxito");
      navigate("/multi-comercios");
    } catch (error) {
      console.error(error);
      showNotification("error", "Error al crear el comercio");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (isProduction()) navigate("/multi-comercios");
    handleSetComercios();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.state?.multiComercio) {
      setMultiComercio(location.state.multiComercio);
      setIsEdit(true);
      if (location.state.multiComercio.comercioPadre) {
        setComercioPadre({
          nombre: location.state.multiComercio.comercioPadre.nombreComercio,
          codigo: location.state.multiComercio.comercioPadre.idComercio,
        });
      }
    }
    handleSetComerciosPadres();
    handleSetComerciosHijos();
    // eslint-disable-next-line
  }, [location.state]);

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        {isEdit ? "AÑADIR MC" : "CREAR MC"}
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <CustomBreadcrumbs
          items={[
            ...breadcrumbItems,
            { label: isEdit ? "AÑADIR MC" : "CREAR MC" },
          ]}
        />
        <Grid item xs={12} md={12}>
          {isEdit && comercioPadre?.nombre ? (
            <h2 style={{ padding: "0rem 0.2rem" }}>{comercioPadre.nombre}</h2>
          ) : (
            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>COMERCIO PADRE</label>
              <Combobox
                rows={comerciosPadres}
                placeholder="Seleccione Comercio Padre"
                selectedRow={comercioPadre}
                onSelect={handleComercioPadreChange}
                style={{ width: "200px" }}
              />
            </Grid>
          )}
        </Grid>
        <br />
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>COMERCIO HIJO</label>
              <Combobox
                rows={comerciosHijosFiltrados}
                placeholder="Seleccione Comercio Hijo"
                selectedRow={comercioHijo}
                onSelect={handleComercioHijoChange}
                style={{ width: "200px" }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{display: 'none'}}>
              <label style={{ color: "black" }}>
                ADJUNTAR ARCHIVO DE BINES PERMITIDOS
              </label>
              <Input
                type="file"
                accept=".xls,.xlsx"
                inputRef={inputFilePermitido}
                onChange={(e) => handleFileUpload(e, setBinPermitido)}
              />
              {binPermitido && (
                <Typography variant="body2" color="textSecondary">
                  Archivo: {binPermitido.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12} sx={{display: 'none'}}>
              <label style={{ color: "black" }}>
                ADJUNTAR ARCHIVO DE BINES RESTRINGIDOS
              </label>
              <Input
                type="file"
                accept=".xls,.xlsx"
                inputRef={inputFileRestringido}
                onChange={(e) => handleFileUpload(e, setBinRestringido)}
              />
              {binRestringido && (
                <Typography variant="body2" color="textSecondary">
                  Archivo: {binRestringido.name}
                </Typography>
              )}
            </Grid>
            {inputsError && (
              <Grid item xs={12} >
                <div style={{ color: "red", marginTop: "10px" }}>
                  {inputsError}
                </div>
              </Grid>
            )}
            <Grid item xs={12} >
              <Button
                style={{ marginRight: "10px" }}
                variant="gray"
                onClick={handleVolver}
              >
                CANCELAR
              </Button>
              <Button onClick={handleCrear}>ACEPTAR</Button>
            </Grid>
          </Grid>
        </form>
      </ShadowBox>
    </>
  );
}
