import React, { useEffect, useState } from "react";
import { useLoader } from "../../context/LoaderContext";
import { getToken } from "../../connection/tokenController";
import {
  listarCommerceDataMcPorIdComercioHijoRequest,
  listarPosDataMcPorIdSucursalMcRequest,
  listarSucursalesMcPorIdCommerceDataRequest,
  obtenerHijosDePadreRequest,
  obtenerTodosLosPadresRequest,
} from "../../connection/mantenedores/multicomercioMantenedor";
import { compararASC, compararFechasDESC } from "../../utils/utilidades";
import { obtenerTxsMCRequest } from "../../connection/mantenedores/transacciones";

const MAX_DAYS_SPAN = 15;

const formatearFechaObtenida = (fecha) => {
  if (!fecha) return "";
  const fechaObj = new Date(fecha);
  const dia = String(fechaObj.getDate()).padStart(2, "0");
  const mes = String(fechaObj.getMonth() + 1).padStart(2, "0");
  const anio = fechaObj.getFullYear();
  return `${anio}-${mes}-${dia}`;
};
function parsearComandos(listado) {
  return listado.map((x) => x.codigo).join(",");
}

export default function useMcTransacciones() {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [comerciosPadres, setComerciosPadres] = useState([]);
  const [comerciosHijos, setComerciosHijos] = useState([]);
  const [sucursalesMc, setSucursalesMc] = useState([]);
  const [listadoPos, setListadoPos] = useState([]);
  const [idComercioPadreSelected, setIdComercioPadreSelected] = useState(0);
  const [idComercioHijoSelected, setIdComercioHijoSelected] = useState(0);
  const [idSucursalMcSelected, setIdSucursalMcSelected] = useState(0);
  const [idPosDataSelected, setIdPosDataSelected] = useState(0);
  const [comandosSeleccionados, setComandosSeleccionados] = useState([]);
  const [transacciones, setTransacciones] = useState([]);

  const [fechas, setFechas] = useState({
    fechaDesde: null,
    fechaHasta: null,
    maxDate: null,
  });

  const handleObtenerComerciosPadres = async () => {
    try {
      showLoader();
      const token = getToken();
      const response = await obtenerTodosLosPadresRequest(token);
      const ordenados = response.sort((a, b) =>
        compararASC(a, b, "nombreComercio")
      );
      setComerciosPadres(
        ordenados.map((comercio) => ({
          codigo: comercio.idComercio,
          nombre: comercio.nombreComercio.toUpperCase(),
        }))
      );
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  const handleObtenerComerciosHijos = async () => {
    try {
      showLoader();
      const token = getToken();
      const response = await obtenerHijosDePadreRequest(
        idComercioPadreSelected,
        token
      );
      const ordenados = response.sort((a, b) =>
        compararASC(a, b, "nombreComercio")
      );

      setComerciosHijos(
        ordenados.map((comercio) => ({
          codigo: comercio.idComercio,
          nombre: comercio.nombreComercio.toUpperCase(),
        }))
      );
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  const handleObtenerSucursales = async () => {
    try {
      showLoader();
      const token = getToken();
      const responseCommerceData =
        await listarCommerceDataMcPorIdComercioHijoRequest(
          idComercioHijoSelected,
          token
        );
      if (responseCommerceData.length === 0) {
        showNotification("error", "No existen sucursales asociadas");
        return;
      }
      const idCommerceData = responseCommerceData[0].idCommerceDataMc;
      const responseSucursales =
        await listarSucursalesMcPorIdCommerceDataRequest(idCommerceData, token);
      if (responseSucursales.length === 0) {
        showNotification("error", "No existen sucursales asociadas");
        return;
      }
      const ordenados = responseSucursales
        .map((sucursal) => ({
          ...sucursal,
          nombreCorto: sucursal.nombreCorto.toLowerCase(),
        }))
        .sort((a, b) => compararASC(a, b, "nombreCorto"));
      setSucursalesMc(
        ordenados.map((sucursal) => ({
          codigo: sucursal.idSucursalMc,
          nombre: sucursal.nombreCorto.toUpperCase(),
        }))
      );
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  const handleObtenerListadoPos = async () => {
    try {
      showLoader();
      const token = getToken();
      const response = await listarPosDataMcPorIdSucursalMcRequest(
        idSucursalMcSelected,
        token
      );
      const ordenados = response.sort((a, b) =>
        compararASC(a, b, "serialNumberMc")
      );
      setListadoPos(
        ordenados.map((comercio) => ({
          codigo: comercio.idPosDataMc,
          nombre: comercio.serialNumberMc.toUpperCase(),
        }))
      );
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };
  const obtenerMaxDate = (fecha) => {
    const fechaMax = new Date(fecha);
    fechaMax.setDate(fecha.getDate() + MAX_DAYS_SPAN);
    return fechaMax;
  };
  const seleccionarFechaDesde = (fecha) => {
    const maxFecha = obtenerMaxDate(fecha);
    console.log(maxFecha)
    setFechas((prev) => ({
      ...prev,
      fechaDesde: fecha,
      maxDate: obtenerMaxDate(fecha),
    }));
  };
  const seleccionarFechaHasta = (fecha) => {
    setFechas((prev) => ({ ...prev, fechaHasta: fecha }));
  };
  const seleccionarComercioPadre = (idComercioPadre) => {
    setIdComercioPadreSelected(idComercioPadre);
  };
  const seleccionarComercioHijo = (idComercioHijo) => {
    setIdComercioHijoSelected(idComercioHijo);
  };
  const seleccionarSucursalMc = (idSucursalMc) => {
    setIdSucursalMcSelected(idSucursalMc);
  };
  const seleccionarPos = (idPosDataMc) => {
    setIdPosDataSelected(idPosDataMc);
  };

  const handleSelectComando = (comando) => {
    if (!comandosSeleccionados.some((x) => x.codigo === comando.codigo)) {
      setComandosSeleccionados([...comandosSeleccionados, comando]);
    }
  };

  const handleDeleteComando = (comando) => {
    setComandosSeleccionados(
      comandosSeleccionados.filter((x) => x.codigo !== comando.codigo)
    );
  };
  const handleBuscarTransacciones = async () => {
      if (!validarData()) {
      return;
    }
    showLoader();
    try {
      const fechaDesdeStr = formatearFechaObtenida(fechas.fechaDesde);
      const fechaHasta = new Date(fechas.fechaHasta);
      fechaHasta.setDate(fechaHasta.getDate() + 1); 
      const fechaHastaStr = formatearFechaObtenida(fechaHasta);
      const comandosParseados = parsearComandos(comandosSeleccionados);
      const respuesta = await obtenerTxsMCRequest(
        idPosDataSelected,
        fechaDesdeStr,
        fechaHastaStr,
        comandosParseados,
        getToken()
      );
      const listadoParseado = respuesta.map((tx) => ({
        ...tx,
        fechaRequest: new Date(tx.fechaRequest),
        fechaResponse: new Date(tx.fechaResponse),
      })).sort((a, b) => compararFechasDESC(a.fechaRequest, b.fechaRequest))
      setTransacciones(listadoParseado);
    } catch (error) {
      console.log(error)
      showNotification(
        "error",
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  const validarData = () => {
    if (idComercioPadreSelected <= 0) {
      showNotification("error", "Seleccione un comercio padre");
      return false;
    }
    if (idComercioHijoSelected <= 0) {
      showNotification("error", "Seleccione un comercio hijo");
      return false;
    }
    if (idSucursalMcSelected <= 0) {
      showNotification("error", "Seleccione una sucursal");
      return false;
    }
    if (idPosDataSelected <= 0) {
      showNotification("error", "Seleccione un POS");
      return false;
    }
    if (!fechas.fechaDesde) {
      showNotification("error", "Seleccione fecha desde");
      return false;
    }
    if (!fechas.fechaHasta) {
      showNotification("error", "Seleccione fecha hasta");
      return false;
    }
    const diferenciaDias =
      (fechas.fechaHasta - fechas.fechaDesde) / (60 * 60 * 24 * 1000);
    if (diferenciaDias < 0) {
      showNotification(
        "error",
        `Las fecha hasta no puede ser menor a la fecha desde`
      );
      return false;
    }
    if (diferenciaDias > MAX_DAYS_SPAN) {
      showNotification(
        "error",
        `Las fechas deben estar en un intervalo de máximo ${MAX_DAYS_SPAN} días`
      );
      return false;
    }
    if (comandosSeleccionados.length === 0) {
      showNotification("error", "Seleccione al menos un comando");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (idComercioPadreSelected === 0) {
      setComerciosHijos([]);
      setIdComercioHijoSelected(prev => (0));
      return;
    }
    handleObtenerComerciosHijos();
    setIdComercioHijoSelected(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idComercioPadreSelected]);

  useEffect(() => {
    if (idComercioHijoSelected === 0) {
      setSucursalesMc([]);
      setIdSucursalMcSelected(0);
      return;
    }
    handleObtenerSucursales();
    setIdSucursalMcSelected(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idComercioHijoSelected]);

  useEffect(() => {
    if (idSucursalMcSelected === 0) {
      setListadoPos([]);
      setIdPosDataSelected(0);
      return;
    }
    handleObtenerListadoPos();
    setIdPosDataSelected(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSucursalMcSelected]);

  useEffect(() => {
    handleObtenerComerciosPadres();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    comerciosPadres,
    comerciosHijos,
    sucursalesMc,
    listadoPos,
    fechas,
    comandosSeleccionados,
    transacciones,
    idComercioHijoSelected,
    idComercioPadreSelected,
    idSucursalMcSelected,
    idPosDataSelected,
    seleccionarComercioPadre,
    seleccionarComercioHijo,
    seleccionarSucursalMc,
    seleccionarPos,
    seleccionarFechaDesde,
    seleccionarFechaHasta,
    handleBuscarTransacciones,
    handleSelectComando,
    handleDeleteComando,
  };
}
