import React from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Collapse,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import XTable from "../XTable/XTable";
import { mcHijosHeaderConfig } from "./multiComercioTableHeaderConfig";
import MultiComerciosHijosRow from "./MultiComerciosHijosRow";

export default function MulticomercioTableRow({ row, events }) {
  const [
    onOpenComercioData,
    onAddComercioHijo,
    onDesvincularHijo,
    onOpenDialogSucursales,
  ] = events;

  const { nombreComercio, numeroComercioGetnet, rut, comerciosHijos } = row;

  return (
    <>
      <TableRow>
        <TableCell sx={{whiteSpace: 'nowrap', width: '1px'}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => onOpenComercioData(row)}
          >
            {comerciosHijos.open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "0px", whiteSpace: "nowrap" }}>
          {numeroComercioGetnet}
        </TableCell>
        <TableCell>{nombreComercio.toUpperCase()}</TableCell>
        <TableCell>{rut}</TableCell>

        <TableCell
          sx={{ padding: "0px 0.5rem", whiteSpace: "nowrap", width: "1px" }}
        >
          <Tooltip title="Añadir Comercio Hijo">
            <IconButton
              style={{ marginLeft: "10px" }}
              aria-label="Añadir MC"
              onClick={() => onAddComercioHijo(row)}
            >
              <AddBusinessIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} style={{ padding: 0 }}>
          <Collapse in={comerciosHijos.open} timeout="auto" unmountOnExit>
            <Box>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <XTable
                  sx={{ maxWidth: "50rem" }}
                  data={comerciosHijos.comercios || []}
                  events={[onDesvincularHijo, onOpenDialogSucursales]}
                  headerConfig={mcHijosHeaderConfig}
                  searchBar={false}
                  RowComponent={MultiComerciosHijosRow}
                />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
