import React, { useState } from "react";
import { useLoader } from "../../../context/LoaderContext";
import { getToken } from "../../../connection/tokenController";
import { validarParams } from "../../../components/MulticomercioTable/SucursalesTable/sucursalesParams/commerceDefaultParams";
import {
  crearCommerceParamsMcRequest,
  editarcommerceparamsmcMcRequest,
  listarCommerceParamsPorSucursalRequest,
} from "../../../connection/mantenedores/multicomercioMantenedor";

export default function useParametros() {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [parametros, setParametros] = useState({});

  const onSaveCommerceParams = async (parametros) => {
    showLoader();
    try {
      const token = getToken();
      if (!validarParametros(parametros)) {
        return;
      }
      if (parametros.idCommerceParam === 0) {
        await crearCommerceParamsMcRequest(parametros, token);
      } else {
        await editarcommerceparamsmcMcRequest(
          parametros.idCommerceParam,
          parametros,
          token
        );
      }
      onObtenerParametros(parametros.idSucursalMc)
      showNotification("success", "Commerce Params creados con éxito");
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      showNotification("error", "Ocurrio un error inesperado");
    } finally {
      hideLoader();
    }
  };
  const validarParametros = (parametros) => {
    const respuestaValidacion = validarParams(parametros);
    if (!respuestaValidacion.success) {
      showNotification("error", respuestaValidacion.message);
      return false;
    }
    return true;
  };

  const onObtenerParametros = async (idSucursalMc) => {
    showLoader();
    try {
      const token = getToken();
      const res = await listarCommerceParamsPorSucursalRequest(
        idSucursalMc,
        token
      );
      setParametros(res.length > 0 ? res[0] : {});
    } catch (error) {
      console.error("Error al seleccionar sucursal:", error);
    } finally {
      hideLoader();
    }
  };
  const onVaciarParametros = () => {
    setParametros({});
  };

  return {
    parametros,
    onVaciarParametros,
    onSaveCommerceParams,
    onObtenerParametros,
  };
}
