import React, { useEffect } from "react";
import { isLoginComercio } from "../../connection/loginController";
import {
  APP_MULTICOMERCIO,
  obtenerAppsHabilitadas,
} from "../../connection/AppsController";
import { useNavigate } from "react-router-dom";
import TransaccionesMc from "../../components/TransaccionesMc/TransaccionesMc";

const listaComandos = [
  { nombre: "120 | VENTA MC ", codigo: 120 },
  { nombre: "122 | ANULACIÓN MC", codigo: 122 },
  { nombre: "123 | DEVOLUCIÓN MC", codigo: 123 },
];

export default function MultiComerciosTransacciones() {
  const navigate = useNavigate();
  useEffect(() => {
    checkearValidRoute();
    // eslint-disable-next-line
  }, []);

  const checkearValidRoute = async () => {
    if (!isLoginComercio()) return;
    const nombresApps = await obtenerAppsHabilitadas();
    if (!nombresApps.includes(APP_MULTICOMERCIO)) {
      navigate("/404");
    }
  };
  
  return (
    <TransaccionesMc
      titulo="MULTICOMERCIO"
      variant="multicomercio"
      comandos={listaComandos}
    />
  );
}
