export const parametrosIniciales = {
  idCommerceParam: 0,
  indicadorBiomoneda: 0,
  indicadorBoleta: 0,
  indicadorNoVendedor: 1,
  indicadorComprobanteComoBoleta: 1,
  indicadorPropina: 1,
  indicadorVuelto: 0,
  indicadorCuotasEmisor: 1,
  minimoCuotasEmisor: "2",
  maximoCuotasEmisor: "60",
  indicadorCuotasComercio: 1,
  minimoCuotasComercio: "1",
  maximoCuotasComercio: "3",
  indicadorCuotasTasaCero: 0,
  minimoCuotasTasaCero: "0",
  maximoCuotasTasaCero: "0",
  indicadorCuotasTasaInteresConocida: 0,
  minimoCuotasTasaInteresConocida: "0",
  maximoCuotasTasaInteresConocida: "0",
  tipoProductoCreditoVisa: 1,
  tipoProductoDebitoVisa: 1,
  tipoProductoDebitoVisaElectron: 1,
  tipoProductoPrepagoVisa: 1,
  tipoProductoCreditoMastercard: 1,
  tipoProductoDebitoMastercard: 1,
  tipoProductoDebitoMaestro: 1,
  tipoProductoPrepagoMastercard: 1,
  tipoProductoCreditoAmex: 1,
  tipoProductoDebitoAmex: 1,
  tipoProductoPrepagoAmex: 1,
  tipoProductoMagna: 1,
  numeroDeFolio: 0,
  posAvance: 0,
  habilitado: true,
};

export const validarParams = (params) => {
  try {
    if (parseInt(params.minimoCuotasEmisor) > parseInt(params.maximoCuotasEmisor)) {
      return generarRespuesta(false, "El máximo de cuotas emisor no puede ser menor al mínimo de cuotas emisor");
    }
    if (parseInt(params.minimoCuotasComercio) > parseInt(params.maximoCuotasComercio)) {
      return generarRespuesta(false, "El máximo de cuotas de comercio no puede ser menor al mínimo de cuotas de comercio");
    }
    if (parseInt(params.minimoCuotasTasaCero) > parseInt(params.maximoCuotasTasaCero)) {
      return generarRespuesta(false, "El máximo de cuotas tasa cero no puede ser menor al mínimo de cuotas tasa cero");
    }
    if (parseInt(params.minimoCuotasTasaInteresConocida) > parseInt(params.maximoCuotasTasaInteresConocida)) {
      return generarRespuesta(false, "El máximo de cuotas de tasa interés conocida no puede ser menor al mínimo de cuotas tasa de interés conocida");
    }
    return generarRespuesta(true)
  } catch (error) {
    return generarRespuesta(false, error.message);
  }
}
const generarRespuesta = (success, message) => {
  return {
    success,
    message
  }
}