import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  Divider,
} from "@mui/material";
import "./dialogos.css";
import NavigationTabs from "../../../components/tabs/NavigationTabs";
import SucursalesMcMantendor from "./sucursalesMc/SucursalesMcMantendor";
import SucursalesCommerceParams from "../SucursalesTable/sucursalesParams/CommerceParamsMc";
import PosDataMc from "../SucursalesTable/posParams/PosDataMc";
import CrearSucursalDialogo from "./sucursalesMc/CrearSucursalDialogo";
import CrearPosParamsDialogo from "../SucursalesTable/posParams/CrearPosParamsDialogo";

export const tabs = {
  sucursalesTab: "SUCURSALES",
  paramsTab: "PARAMETROS DE COMERCIO",
  posTab: "POS DATA",
  crearSucursalTab: "CREAR SUCURSAL",
  crearPosTab: "CREAR POS",
};
const tabList = [tabs.sucursalesTab, tabs.paramsTab, tabs.posTab];

export default function SucursalesMcDialog({
  open,
  onClose,
  idCommerceDataMc,
}) {
  const [selectedTab, setSelectedTab] = useState(tabList[0]);
  const nombreComercio = localStorage.getItem("nombreComercio");

  const handleSelectTab = (tabIndex) => {
    setSelectedTab(tabList[tabIndex]);
  };
  const onChangeTabInternal = (tab) => {
    setSelectedTab(tab);
  };
  useEffect(() => {
    setSelectedTab(tabList[0]);
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      className="custom-dialog"
    >
      <NavigationTabs tabs={tabList} onSelectTab={handleSelectTab} />
      <Divider />
      <SucursalesMcDialogContent
        tab={selectedTab}
        idCommerceDataMc={idCommerceDataMc}
        nombreComercio={nombreComercio}
        onChangeTabInternal={onChangeTabInternal}
      />
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
function SucursalesMcDialogContent({
  tab,
  idCommerceDataMc,
  nombreComercio,
  onChangeTabInternal,
}) {
  if (tab === tabs.sucursalesTab) {
    return (
      <SucursalesMcMantendor
        idCommerceDataMc={idCommerceDataMc}
        nombreComercio={nombreComercio}
        onChangeTabInternal={onChangeTabInternal}
      />
    );
  }
  if (tab === tabs.paramsTab) {
    return (
      <SucursalesCommerceParams
        idCommerceDataMc={idCommerceDataMc}
        nombreComercio={nombreComercio}
        onChangeTabInternal={onChangeTabInternal}
      />
    );
  }
  if (tab === tabs.posTab)
    return (
      <PosDataMc
        idCommerceDataMc={idCommerceDataMc}
        nombreComercio={nombreComercio}
        onChangeTabInternal={onChangeTabInternal}
      />
    );
  if (tab === tabs.crearPosTab)
    return (
      <CrearPosParamsDialogo
        idCommerceDataMc={idCommerceDataMc}
        nombreComercio={nombreComercio}
        onChangeTabInternal={onChangeTabInternal}
      />
    );
  return (
    <CrearSucursalDialogo
      idCommerceDataMc={idCommerceDataMc}
      nombreComercio={nombreComercio}
      onChangeTabInternal={onChangeTabInternal}
    />
  );
}
