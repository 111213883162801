import React, { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import "./style.css";
const estilos = {
  container: {
    display: "flex",
  },
};

export default function FiltroTexto({
  onFilter,
  value,
  onChange,
  selected,
  variant,
  placeholder,
  onFocus,
  onBlur,
  style,
  onUnSelect
}) {
  const [texto, setTexto] = useState("");
  const generalTimeOut = useRef(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    clearTimeout(generalTimeOut.current);
    generalTimeOut.current = setTimeout(() => {
      if (onFilter)
        onFilter(texto);
    }, 500);

    return () => clearTimeout(generalTimeOut.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texto, onFilter]);

  useEffect(() => {
    setTexto(value || "");
  }, [value]);

  const handleChangeTexto = (e) => {
    setTexto(e.target.value);
    if (typeof onChange === "function") {
      onChange(e);
    }
  };
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div style={estilos.container}>
      <Input
        variant={variant}
        type="text"
        placeholder={placeholder || "Buscar..."}
        onChange={handleChangeTexto}
        value={texto}
        onFocus={onFocus}
        onBlur={onBlur}
        style={style || null}
      />
      {isSelected && (
        <button className="filtro-texto-unselectbtn" onClick={onUnSelect}>
          x
        </button>
      )}
    </div>
  );
}
